import React, {useState} from "react";
import './analyse.scss';
import analyseIcon from "../../../assets/images/icons/analyse.svg";
import energy from "../../../assets/images/energie1.jpg";
import education from "../../../assets/images/education1.jpg";
import eau from "../../../assets/images/eau1.jpg";
import sante from "../../../assets/images/sante1.jpg";
import { Link } from "react-router-dom";
import { baseLayers, countyPositions } from "../../../assets/constants/const";
import Map from "../../carte-pages/carte-map/Mapt";
import {
    AddBTBuffer,
    AddPrefectures,
    EcoleElectrifiee, EcoleNonElectrifiee,
    EducationCollege,
    EducationJardin,
    EducationLycee,
    EducationPrimaire,
    EducationUniversite, LampadairesNotWorking, LampadairesWorking, LigneBT, LigneMT, PoteauBT, PoteauMT
} from "./education_new/layers";

const Analyse = () => {

    const [subdivisionLevel, setSubdivisionLevel] = useState("prefecture");
    const [selectedRegion, setSelectedRegion] = useState(0);
    const [selectedPrefecture, setSelectedPrefecture] = useState(0);
    const [selectedCommune, setSelectedCommune] = useState(0);

    // eslint-disable-next-line
    const [mapData, setMapData] = useState({});
    // eslint-disable-next-line
    const [selectedCounty, setSelectedCounty] = useState(0);
    const [radioVal, setRadioVal] = useState("type");
    const [checkbox0, setCheckbox0] = useState("pref");
    const [checkbox20, setCheckbox20] = useState("");
    const [checkbox30, setCheckbox30] = useState("");
    const [checkbox31, setCheckbox31] = useState("");
    const [checkbox1, setCheckbox1] = useState("");
    const [checkbox2, setCheckbox2] = useState("");
    const [checkbox3, setCheckbox3] = useState("");
    const [checkbox4, setCheckbox4] = useState("");
    const [checkbox5, setCheckbox5] = useState("");
    const [checkbox6, setCheckbox6] = useState("");
    const [checkbox7, setCheckbox7] = useState("");
    const [checkbox8, setCheckbox8] = useState("");
    const [checkbox9, setCheckbox9] = useState("");
    const [checkbox10, setCheckbox10] = useState("");
    const [checkbox11, setCheckbox11] = useState("");
    const [checkbox12, setCheckbox12] = useState("");
    const [checkbox13, setCheckbox13] = useState("");

    const layers = [
        EducationUniversite,
        EducationLycee,
        EducationCollege,
        EducationPrimaire,
        EducationJardin,
        EcoleElectrifiee,
        EcoleNonElectrifiee,
        LampadairesWorking,
        LampadairesNotWorking,
        LigneBT,
        LigneMT,
        PoteauBT,
        PoteauMT,
        AddPrefectures,
        AddBTBuffer
    ];

    const displayedLayers = {
        [AddPrefectures.slug]: checkbox0,
        [AddBTBuffer.slug]: checkbox20,
        [PoteauBT.slug]: checkbox1,
        [PoteauMT.slug]: checkbox2,
        //[PoteauHT.slug]: checkbox3,
        [LigneBT.slug]: checkbox4,
        [LigneMT.slug]: checkbox5,
        //[LigneHT.slug]: checkbox6,
        [AddBTBuffer.slug]: checkbox20,
        [EducationUniversite.slug]: checkbox7,
        [EducationLycee.slug]: checkbox8,
        [EducationCollege.slug]: checkbox9,
        [EducationPrimaire.slug]: checkbox10,
        [EducationJardin.slug]: checkbox11,
        [LampadairesWorking.slug]: checkbox12,
        [LampadairesNotWorking.slug]: checkbox13,
        [EcoleElectrifiee.slug]: checkbox30,
        [EcoleNonElectrifiee.slug]: checkbox31,
    };

    const layerSelectionButtons = {
        radioVal,
        setRadioVal,
        checkbox1,
        setCheckbox1,
        checkbox2,
        setCheckbox2,
        checkbox3,
        setCheckbox3,
        checkbox4,
        setCheckbox4,
        checkbox5,
        setCheckbox5,
        checkbox6,
        setCheckbox6,
        checkbox7,
        setCheckbox7,
        checkbox8,
        setCheckbox8,
        checkbox9,
        setCheckbox9,
        checkbox10,
        setCheckbox10,
        checkbox11,
        setCheckbox11,
        checkbox12,
        setCheckbox12,
        checkbox13,
        setCheckbox13,
        checkbox0,
        setCheckbox0,
        checkbox20,
        setCheckbox20,
        checkbox30,
        setCheckbox30,
        checkbox31,
        setCheckbox31
    };

    return (
        <div className="analayse-container">
            <div className="analayse-container-sidebar">
                <div className="analayse-container-sidebar-title">
                    <h2>
                        <img src={analyseIcon} alt="icon"/>
                        <span>Indicateurs</span>
                    </h2>
                </div>
                <div className="analayse-container-sidebar-content">
                    <p>
                        Basée sur les données issues des statistiques démographiques et des données collationnées par le projet PRISE, cette section propose une analyse des secteurs clés de Savanes.
                    </p>
                    <div className="analayse-container-sidebar-content-list">
                        <div className="analayse-container-sidebar-content-list-item">
                            <Link className="analayse-container-sidebar-content-list-item-link" to={`/new-project/analyse/education`}>
                                <img src={education} alt="education"/>
                                {/*<div className="analayse-container-sidebar-content-list-item-link-placeholder">
                                    <h4>placeholder</h4>
                                </div>*/}
                                <div className="analayse-container-sidebar-content-list-item-link-text">
                                    <h5>Éducation</h5>
                                    <p>
                                        Accessibilité des écoles primaires, indicateurs sur la taille des classes et conditions d'apprentissage.
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className="analayse-container-sidebar-content-list-item">
                            <Link className="analayse-container-sidebar-content-list-item-link" to={`/new-project/analyse/sante`}>
                                <img src={sante} alt="sante"/>
                                {/*<div className="analayse-container-sidebar-content-list-item-link-placeholder">
                                    <h4>placeholder</h4>
                                </div>*/}
                                <div className="analayse-container-sidebar-content-list-item-link-text">
                                    <h5>Santé</h5>
                                    <p>
                                        Indicateurs de santé tels que le nombre de médecins par habitant et l'accessibilité aux services de santé.
                                    </p>
                                </div>
                            </Link>
                        </div>
                        {/*<div className="analayse-container-sidebar-content-list-item">
                            <Link className="analayse-container-sidebar-content-list-item-link" to={`/new-project/analyse/electricite`}>
                                <div className="analayse-container-sidebar-content-list-item-link-placeholder">
                                    <h4>placeholder</h4>
                                </div>
                                <div className="analayse-container-sidebar-content-list-item-link-text">
                                    <h5>Électricité</h5>
                                    <p>
                                        À partir de divers indicateurs, visualisez les zones les plus enclavées.
                                    </p>
                                </div>
                            </Link>
                        </div>*/}
                        <div className="analayse-container-sidebar-content-list-item">
                            <Link className="analayse-container-sidebar-content-list-item-link" to={`/new-project/analyse/eau`}>
                                <img src={eau} alt="eau"/>
                               {/* <div className="analayse-container-sidebar-content-list-item-link-placeholder">
                                    <h4>placeholder</h4>
                                </div>*/}
                                <div className="analayse-container-sidebar-content-list-item-link-text">
                                    <h5>Eau</h5>
                                    <p>
                                        Structure du réseau d'approvisionnement en eau et accès à l'eau potable.
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className="analayse-container-sidebar-content-list-item">
                            <Link className="analayse-container-sidebar-content-list-item-link" to={`/new-project/analyse/energie`}>
                                <img src={energy} alt="energie"/>
                               {/* <div className="analayse-container-sidebar-content-list-item-link-placeholder">
                                    <h4>placeholder</h4>
                                </div>*/}
                                <div className="analayse-container-sidebar-content-list-item-link-text">
                                    <h5>Énergie</h5>
                                    <p>
                                        Couverture du réseau électrique actuel et futur ainsi que de son impact sur la population locale.
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="analayse-container-content">
                <Map
                    subdivisionLevel={subdivisionLevel}
                    setSubdivisionLevel={setSubdivisionLevel}
                    selectedRegion={selectedRegion}
                    setSelectedRegion={setSelectedRegion}
                    selectedPrefecture={selectedPrefecture}
                    setSelectedPrefecture={setSelectedPrefecture}
                    selectedCommune={selectedCommune}
                    setSelectedCommune={setSelectedCommune}

                    layers={layers}
                    displayedLayers={displayedLayers}
                    mapData={mapData}
                    latLng={countyPositions[selectedCounty].latLng}
                    zoom={countyPositions[selectedCounty].zoom}
                    baseLayers={baseLayers}
                    layerSelectionButtons={layerSelectionButtons}
                />
            </div>
        </div>
    )
}

export default Analyse;