import React from 'react';
import './notFound.css'
import {NavLink} from "react-router-dom";

const NotFound = () => {
    return (
        <div className="not-found">
            <div className="not-found-inner">
                <p>Page introuvable</p>
                <NavLink to="/">Retour au tableau de board</NavLink>
            </div>
        </div>
    )
}

export default NotFound;
