import React from "react";
import './new-project.scss';
import SideMenu from "./side-menu/sideMenu";
import {Route, Switch, Redirect} from "react-router-dom";
import Analyse from "./analyse/analyse";
import Education from "./analyse/education/education";

const NewProject = () => {
    return (
        <div>
            <div className="new-project-container">
                <div className="new-project-container-menu">
                    <SideMenu/>
                </div>
                <div className="new-project-container-content">
                    <Switch>
                        <Route path="/new-project/analyse" component={Analyse} />
                        <Route path="new-project/analyse/education" component={Education} />
                        <Route>
                            <Redirect to="/not-found" />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    )
}

export default NewProject;