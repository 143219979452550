import React from "react";
import "./Footer.scss";
import MitsioMotuLogo from "../../../assets/images/Mitsio-Motu-logo.png";

const Footer = () => (
    <footer className="footer">
        <div className="footer-inner">
            <div className="footer-inner-logo">
                <img src={MitsioMotuLogo} alt="Mitsio Motu logo" />
            </div>
            <div className="footer-inner-rec">
                <a href={`mailto:recensement-togo@mitsiomotu.com`}>recensement-togo@mitsiomotu.com</a>
                <p>+234 00 00 00 (WhatsApp)</p>
            </div>
            <div className="footer-inner-info">
                <p>Développé par Mitsio Motu, 2021</p>
                <p>Les informations montrées peuvent être confidentielles.</p>
            </div>
        </div>
    </footer>
);

export default Footer;
