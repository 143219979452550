import L from "leaflet";

/* DATA TO IMPORT */

export const dataLoadInfos = [
  {
    dataUrl: "/local_db/education/Etablissements_savanes.geojson",
    fieldName: "educationInfo",
  },
  {
    dataUrl: "/local_db/eclairage/Lampadaires_savanes.geojson",
    fieldName: "lampadaireInfo",
  },
  {
    dataUrl: "/local_db/electricite/BTPoteaux_savanes.geojson",
    fieldName: "poteauBTInfo",
  },
  {
    dataUrl: "/local_db/electricite/MTPoteaux_savanes.geojson",
    fieldName: "poteauMTInfo",
  },
  {
    dataUrl: "/local_db/electricite/HTPoteaux_savanes.geojson",
    fieldName: "poteauHTInfo",
  },
  {
    dataUrl: "/local_db/electricite/BTLignes_savanes.geojson",
    fieldName: "ligneBTInfo",
  },
  {
    dataUrl: "/local_db/electricite/MTLignes_savanes.geojson",
    fieldName: "ligneMTInfo",
  },
  {
    dataUrl: "/local_db/electricite/HTLignes_savanes.geojson",
    fieldName: "ligneHTInfo",
  },
  {
    dataUrl: "/geodata/prefecture_savanes.geojson",
    fieldName: "prefectureInfo",
  },
  {
    dataUrl: "/geodata/reprojcantons_togo_v2.geojson",
    fieldName: "cantonInfo",
  },
  {
    dataUrl: "/local_db/electricite/lignes_bt_buffer_savanes.geojson",
    fieldName: "bufferInfo",
  },
  {
    dataUrl: "/local_db/new/Education/edu_access_ecole_region.geojson",
    fieldName: "eduAccessEcoleInfo",
  },
  {
    dataUrl: "/local_db/new/Education/edu_eleves_prof.geojson",
    fieldName: "eduEleveProfInfo",
  },
  {
    dataUrl: "/local_db/new/Education/edu_eleves_sans_bat.geojson",
    fieldName: "eduEleveSansBatInfo",
  },
  {
    dataUrl: "/local_db/new/Education/edu_access_ecole.geojson",
    fieldName: "eduAccessEcoleCantonInfo",
  },
  {
    dataUrl: "/local_db/new/Education/edu_ecoles_point.geojson",
    fieldName: "eduEcolePointInfo",
  },
  {
    dataUrl: "/local_db/new/Education/ecoles_sans_toilettes.geojson",
    fieldName: "ecolesSansToilettes",
  },
  {
    dataUrl:
      "/local_db/new/Education/ecoles_sans_electricite_eclairage.geojson",
    fieldName: "ecolesSansElectriciteEclairageInfo",
  },
  {
    dataUrl: "/local_db/new/Education/professeurs_volontaires_perc.geojson",
    fieldName: "professeursVolontaires",
  },
  {
    dataUrl: "/local_db/new/population_hexagons.geojson",
    fieldName: "populationInfo",
  },
];

/*export const dataLoadInfos = [
  {
    dataUrl:
      "/local_db/education/20210129-etablissement_education.geojson",
    fieldName: "educationInfo",
  },
];*/

let ligneBTColor = {
  //color: "#f2d338",
  color: "#A7D129",
  weight: 3,
  opacity: 1,
  forceZIndex: 120,
};

let ligneMTColor = {
  //color: "#db921d",
  color: "orange",
  weight: 5,
  opacity: 1,
  forceZIndex: 2000,
};

/*
let ligneHTColor = {
    color: "#89aa97",
    weight: 8,
    opacity: 1,
};
*/

let poteauBTColor = {
  color: "blue",
  weight: 1,
  opacity: 1,
  radius: 2,
};

let poteauMTColor = {
  color: "red",
  weight: 1,
  opacity: 1,
  radius: 5,
};

/*
let poteauHTColor = {
    color: "#06FF00",
    weight: 1,
    opacity: 1,
    radius: 2
};
*/

/* ICONS */

/*

const poteauBTIcon = new L.Icon({
    iconUrl: "/images/electricite/Pbasse-tension.svg",
    shadowUrl: "",
    iconSize: [20, 20], // size of the icon
    shadowSize: [16, 16], // size of the shadow
    iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0], // the same for the shadow
    popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});

const poteauMTIcon = new L.Icon({
    iconUrl: "/images/electricite/Pmoyenne-tension.svg",
    shadowUrl: "",
    iconSize: [20, 20], // size of the icon
    shadowSize: [16, 16], // size of the shadow
    iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0], // the same for the shadow
    popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});

const poteauHTIcon = new L.Icon({
    iconUrl: "/images/electricite/Phaute-tension.svg",
    shadowUrl: "",
    iconSize: [20, 20], // size of the icon
    shadowSize: [16, 16], // size of the shadow
    iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0], // the same for the shadow
    popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});
*/

const universiteIcon = new L.Icon({
  iconUrl: "/images/etablissement/university.svg",
  shadowUrl: "",
  iconSize: [30, 30], // size of the icon
  shadowSize: [16, 16], // size of the shadow
  iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
  shadowAnchor: [0, 0], // the same for the shadow
  popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});

const lyceeIcon = new L.Icon({
  iconUrl: "/images/etablissement/lycee.svg",
  shadowUrl: "",
  iconSize: [30, 30], // size of the icon
  shadowSize: [16, 16], // size of the shadow
  iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
  shadowAnchor: [0, 0], // the same for the shadow
  popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});

const collegeIcon = new L.Icon({
  iconUrl: "/images/etablissement/college.svg",
  shadowUrl: "",
  iconSize: [30, 30], // size of the icon
  shadowSize: [16, 16], // size of the shadow
  iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
  shadowAnchor: [0, 0], // the same for the shadow
  popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});

const primaireIcon = new L.Icon({
  iconUrl: "/images/etablissement/primaire.svg",
  shadowUrl: "",
  iconSize: [30, 30], // size of the icon
  shadowSize: [16, 16], // size of the shadow
  iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
  shadowAnchor: [0, 0], // the same for the shadow
  popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});

const jardinIcon = new L.Icon({
  iconUrl: "/images/etablissement/maternelle.svg",
  shadowUrl: "",
  iconSize: [30, 30], // size of the icon
  shadowSize: [16, 16], // size of the shadow
  iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
  shadowAnchor: [0, 0], // the same for the shadow
  popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});

/*
const lampIcon = new L.Icon({
    iconUrl: "/images/eclairage/street-lamp-icon.svg",
    shadowUrl: "",
    iconSize: [14, 14], // size of the icon
    shadowSize: [16, 16], // size of the shadow
    iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0], // the same for the shadow
    popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});

const lampIcon2 = new L.Icon({
    iconUrl: "/images/eclairage/street-lamp-icon-2.svg",
    shadowUrl: "",
    iconSize: [14, 14], // size of the icon
    shadowSize: [16, 16], // size of the shadow
    iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0], // the same for the shadow
    popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});
*/

let lampadaireWorkingColor = {
  color: "purple",
  weight: 1,
  opacity: 1,
  radius: 2,
};

let lampadaireNotWorkingColor = {
  color: "black",
  weight: 1,
  opacity: 1,
  radius: 5,
};

/* LAYERS TO CREATE */

export const PoteauBT = {
  slug: "poteauBT",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.poteauBTInfo;

    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {
        const { checkbox1 } = layerSelectionButtons;

        //TBM?
        // instead of using a filter, just display non-selected items in grey
        if (checkbox1 === "poteauBT") {
          //if (feature.properties["Type d'établissement 1"] === "Pré Scolaire") {

          return L.circleMarker(latlng, poteauBTColor);
          //return L.marker(latlng, {icon: poteauBTIcon});
        }
      },
      onEachFeature: function (feature, layer) {
        layer
          .bindPopup(
            "Matériau :  " +
              feature.properties["poteau_materiau"] +
              "<br>" +
              "Qualité poteau: " +
              feature.properties["poteau_qualite"] +
              "<br>" +
              "Mise à terre? :  " +
              feature.properties["mise_terre"] +
              "<br>"
          )
          .addTo(map);
      },
      style: function (feature) {
        return poteauBTColor;
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

export const PoteauMT = {
  slug: "poteauMT",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.poteauMTInfo;

    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {
        const { checkbox2 } = layerSelectionButtons;

        //TBM?
        // instead of using a filter, just display non-selected items in grey
        if (checkbox2 === "poteauMT") {
          //if (feature.properties["Type d'établissement 1"] === "Pré Scolaire") {

          return L.circleMarker(latlng, poteauMTColor);
          //return L.marker(latlng, {icon: poteauMTIcon});
        }
      },
      onEachFeature: function (feature, layer) {
        layer
          .bindPopup(
            "Matériau :  " +
              feature.properties["poteau_materiau"] +
              "<br>" +
              "Qualité poteau:  " +
              feature.properties["poteau_qualite"] +
              "<br>" +
              "Mise à terre? :  " +
              feature.properties["mise_terre"] +
              "<br>"
          )
          .addTo(map);
      },
      style: function (feature) {
        return poteauMTColor;
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

export const LigneBT = {
  slug: "ligneBT",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    //console.log("Ligne Base tensions");
    const data = dataSource.ligneBTInfo;
    //console.log(data);
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {
        const { checkbox4 } = layerSelectionButtons;

        //TBM?
        // instead of using a filter, just display non-selected items in grey
        if (checkbox4 === "ligneMT") {
          //if (feature.properties["Type d'établissement 1"] === "Pré Scolaire") {
          //return L.marker(latlng, {icon: poteauMTIcon});
        }
      },
      onEachFeature: function (feature, layer) {
        layer
          .bindPopup(
            "Ligne Souterraine? :  " +
              feature.properties["ligne_sousterr"] +
              "<br>" +
              "Public ou Privé? :  " +
              feature.properties["ligne_propriete"] +
              "<br>" +
              "Nouvelle Ligne? :  " +
              feature.properties["ligne_nouv"] +
              "<br>" +
              "Préfecture :  " +
              feature.properties["prefecture_projet"] +
              "<br>"
          )
          .addTo(map);
      },
      style: function (feature) {
        return ligneBTColor;
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

export const LigneMT = {
  slug: "ligneMT",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    //console.log("poteau M T");
    const data = dataSource.ligneMTInfo;

    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {
        const { checkbox5 } = layerSelectionButtons;

        //TBM?
        // instead of using a filter, just display non-selected items in grey
        if (checkbox5 === "ligneMT") {
          //if (feature.properties["Type d'établissement 1"] === "Pré Scolaire") {
          //return L.marker(latlng, {icon: poteauMTIcon});
        }
      },
      onEachFeature: function (feature, layer) {
        layer
          .bindPopup(
            "Ligne Souterraine? :  " +
              feature.properties["ligne_sousterr"] +
              "<br>" +
              "Public ou Privé? :  " +
              feature.properties["ligne_propriete"] +
              "<br>" +
              "Nouvelle Ligne? :  " +
              feature.properties["ligne_nouv"] +
              "<br>" +
              "Préfecture :  " +
              feature.properties["prefecture_projet"] +
              "<br>"
          )
          .addTo(map);
      },
      style: function (feature) {
        return ligneMTColor;
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

//**************** Ecole Electrifiee ou non  *******************///
export const EcoleElectrifiee = {
  slug: "ecoleElectrifiee",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.educationInfo;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {
        //console.log(feature);
        const { checkbox30 } = layerSelectionButtons;

        //TBM?
        // instead of using a filter, just display non-selected items in grey
        if (
          checkbox30 === "ecoleElectrifiee" &&
          feature.properties["elec_acces"] &&
          feature.properties["elec_acces"] === "Oui"
        ) {
          //if (feature.properties["Type d'établissement 1"] === "Pré Scolaire") {

          //console.log(feature);
          return L.marker(latlng, { icon: lyceeIcon });
        }
      },
      onEachFeature: function (feature, layer) {
        layer
          .bindPopup(
            "Nom de l'établissement :  " +
              feature.properties["etablissement_nom"] +
              "<br>" +
              "Type d'établissement :  " +
              feature.properties["etablissement_categorie"] +
              "<br>" +
              "Public ou Privé? :  " +
              feature.properties["etablissement_secteur"] +
              "<br>" +
              "Canton :  " +
              feature.properties["canton_nom"] +
              "<br>" +
              "Effectif :  " +
              feature.properties["eleve_nbr"] +
              "<br>"
            /*"<br>" +
                        "Élèves / Classe -  " +
                        (feature.properties["Eleves / Classe"] &&
                        parseInt(feature.properties["Eleves / Classe"], 10) > 0
                          ? parseInt(feature.properties["Eleves / Classe"], 10)
                          : "inconnu") +
                        (layerSelectionButtons.loggedIn
                          ? "<br>" +
                            "Contact Direction -  " +
                            feature.properties["Autorités/Contacts 1"]
                          : "")
                          */
          )
          .addTo(map);
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

export const EcoleNonElectrifiee = {
  slug: "ecoleNonElectrifiee",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.educationInfo;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {
        //console.log(feature);
        const { checkbox31 } = layerSelectionButtons;

        //TBM?
        // instead of using a filter, just display non-selected items in grey
        if (
          checkbox31 === "ecoleNonElectrifiee" &&
          feature.properties["elec_acces"] &&
          feature.properties["elec_acces"] === "Non"
        ) {
          //console.log(feature);
          return L.marker(latlng, { icon: universiteIcon });
        }
      },
      onEachFeature: function (feature, layer) {
        layer
          .bindPopup(
            "Nom de l'établissement :  " +
              feature.properties["etablissement_nom"] +
              "<br>" +
              "Type d'établissement :  " +
              feature.properties["etablissement_categorie"] +
              "<br>" +
              "Public ou Privé? :  " +
              feature.properties["etablissement_secteur"] +
              "<br>" +
              "Canton :  " +
              feature.properties["canton_nom"] +
              "<br>" +
              "Effectif :  " +
              feature.properties["eleve_nbr"] +
              "<br>"
            /*"<br>" +
                        "Élèves / Classe -  " +
                        (feature.properties["Eleves / Classe"] &&
                        parseInt(feature.properties["Eleves / Classe"], 10) > 0
                          ? parseInt(feature.properties["Eleves / Classe"], 10)
                          : "inconnu") +
                        (layerSelectionButtons.loggedIn
                          ? "<br>" +
                            "Contact Direction -  " +
                            feature.properties["Autorités/Contacts 1"]
                          : "")
                          */
          )
          .addTo(map);
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

//********* END

export const EducationUniversite = {
  slug: "educationUniversite",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.educationInfo;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {
        //console.log(feature);
        const { checkbox7 } = layerSelectionButtons;

        //TBM?
        // instead of using a filter, just display non-selected items in grey
        if (
          checkbox7 === "universite" &&
          feature.properties["etablissement_categorie"] &&
          feature.properties["etablissement_categorie"]
            .toLowerCase()
            .includes("universite")
        ) {
          //if (feature.properties["Type d'établissement 1"] === "Pré Scolaire") {

          //console.log(feature);
          return L.marker(latlng, { icon: lyceeIcon });
        }
      },
      onEachFeature: function (feature, layer) {
        layer
          .bindPopup(
            "Nom de l'établissement :  " +
              feature.properties["etablissement_nom"] +
              "<br>" +
              "Type d'établissement :  " +
              feature.properties["etablissement_categorie"] +
              "<br>" +
              "Public ou Privé? :  " +
              feature.properties["etablissement_secteur"] +
              "<br>" +
              "Canton :  " +
              feature.properties["canton_nom"] +
              "<br>" +
              "Effectif :  " +
              feature.properties["eleve_nbr"] +
              "<br>"
            /*"<br>" +
                        "Élèves / Classe -  " +
                        (feature.properties["Eleves / Classe"] &&
                        parseInt(feature.properties["Eleves / Classe"], 10) > 0
                          ? parseInt(feature.properties["Eleves / Classe"], 10)
                          : "inconnu") +
                        (layerSelectionButtons.loggedIn
                          ? "<br>" +
                            "Contact Direction -  " +
                            feature.properties["Autorités/Contacts 1"]
                          : "")
                          */
          )
          .addTo(map);
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

export const EducationLycee = {
  slug: "educationLycee",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.educationInfo;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {
        //console.log(feature);
        const { checkbox8 } = layerSelectionButtons;

        //TBM?
        // instead of using a filter, just display non-selected items in grey
        if (
          checkbox8 === "lycee" &&
          feature.properties["etablissement_categorie"] &&
          feature.properties["etablissement_categorie"]
            .toLowerCase()
            .includes("lycée")
        ) {
          //console.log(feature);
          return L.marker(latlng, { icon: universiteIcon });
        }
      },
      onEachFeature: function (feature, layer) {
        layer
          .bindPopup(
            "Nom de l'établissement :  " +
              feature.properties["etablissement_nom"] +
              "<br>" +
              "Type d'établissement :  " +
              feature.properties["etablissement_categorie"] +
              "<br>" +
              "Public ou Privé? :  " +
              feature.properties["etablissement_secteur"] +
              "<br>" +
              "Canton :  " +
              feature.properties["canton_nom"] +
              "<br>" +
              "Effectif :  " +
              feature.properties["eleve_nbr"] +
              "<br>"
            /*"<br>" +
                        "Élèves / Classe -  " +
                        (feature.properties["Eleves / Classe"] &&
                        parseInt(feature.properties["Eleves / Classe"], 10) > 0
                          ? parseInt(feature.properties["Eleves / Classe"], 10)
                          : "inconnu") +
                        (layerSelectionButtons.loggedIn
                          ? "<br>" +
                            "Contact Direction -  " +
                            feature.properties["Autorités/Contacts 1"]
                          : "")
                          */
          )
          .addTo(map);
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

export const EducationCollege = {
  slug: "educationCollege",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.educationInfo;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {
        //console.log(feature);
        const { checkbox9 } = layerSelectionButtons;

        //TBM?
        // instead of using a filter, just display non-selected items in grey
        if (
          checkbox9 === "college" &&
          feature.properties["etablissement_categorie"] &&
          feature.properties["etablissement_categorie"]
            .toLowerCase()
            .includes("college")
        ) {
          return L.marker(latlng, { icon: collegeIcon });
        }
      },
      onEachFeature: function (feature, layer) {
        layer
          .bindPopup(
            "Nom de l'établissement :  " +
              feature.properties["etablissement_nom"] +
              "<br>" +
              "Type d'établissement :  " +
              feature.properties["etablissement_categorie"] +
              "<br>" +
              "Public ou Privé? :  " +
              feature.properties["etablissement_secteur"] +
              "<br>" +
              "Canton :  " +
              feature.properties["canton_nom"] +
              "<br>" +
              "Effectif :  " +
              feature.properties["eleve_nbr"] +
              "<br>"
            /*"<br>" +
                        "Élèves / Classe -  " +
                        (feature.properties["Eleves / Classe"] &&
                        parseInt(feature.properties["Eleves / Classe"], 10) > 0
                          ? parseInt(feature.properties["Eleves / Classe"], 10)
                          : "inconnu") +
                        (layerSelectionButtons.loggedIn
                          ? "<br>" +
                            "Contact Direction -  " +
                            feature.properties["Autorités/Contacts 1"]
                          : "")
                          */
          )
          .addTo(map);
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

export const EducationPrimaire = {
  slug: "educationPrimaire",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.educationInfo;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {
        //console.log(feature);
        const { checkbox10 } = layerSelectionButtons;

        //TBM?
        // instead of using a filter, just display non-selected items in grey
        if (
          checkbox10 === "ecole_primaire" &&
          feature.properties["etablissement_categorie"] &&
          feature.properties["etablissement_categorie"]
            .toLowerCase()
            .includes("primaire")
        ) {
          return L.marker(latlng, { icon: primaireIcon });
        }
      },
      onEachFeature: function (feature, layer) {
        layer
          .bindPopup(
            "Nom de l'établissement :  " +
              feature.properties["etablissement_nom"] +
              "<br>" +
              "Type d'établissement :  " +
              feature.properties["etablissement_categorie"] +
              "<br>" +
              "Public ou Privé? :  " +
              feature.properties["etablissement_secteur"] +
              "<br>" +
              "Canton :  " +
              feature.properties["canton_nom"] +
              "<br>" +
              "Effectif :  " +
              feature.properties["eleve_nbr"] +
              "<br>"
            /*"<br>" +
                        "Élèves / Classe -  " +
                        (feature.properties["Eleves / Classe"] &&
                        parseInt(feature.properties["Eleves / Classe"], 10) > 0
                          ? parseInt(feature.properties["Eleves / Classe"], 10)
                          : "inconnu") +
                        (layerSelectionButtons.loggedIn
                          ? "<br>" +
                            "Contact Direction -  " +
                            feature.properties["Autorités/Contacts 1"]
                          : "")
                          */
          )
          .addTo(map);
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

export const EducationJardin = {
  slug: "educationJardin",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.educationInfo;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {
        //console.log(feature);
        const { checkbox11 } = layerSelectionButtons;

        //TBM?
        // instead of using a filter, just display non-selected items in grey
        if (
          checkbox11 === "jardin" &&
          feature.properties["etablissement_categorie"] &&
          feature.properties["etablissement_categorie"]
            .toLowerCase()
            .includes("jardin")
        ) {
          return L.marker(latlng, { icon: jardinIcon });
        }
      },
      onEachFeature: function (feature, layer) {
        layer
          .bindPopup(
            "Nom de l'établissement :  " +
              feature.properties["etablissement_nom"] +
              "<br>" +
              "Type d'établissement :  " +
              feature.properties["etablissement_categorie"] +
              "<br>" +
              "Public ou Privé? :  " +
              feature.properties["etablissement_secteur"] +
              "<br>" +
              "Canton :  " +
              feature.properties["canton_nom"] +
              "<br>" +
              "Effectif :  " +
              feature.properties["eleve_nbr"] +
              "<br>"
            /*"<br>" +
                        "Élèves / Classe -  " +
                        (feature.properties["Eleves / Classe"] &&
                        parseInt(feature.properties["Eleves / Classe"], 10) > 0
                          ? parseInt(feature.properties["Eleves / Classe"], 10)
                          : "inconnu") +
                        (layerSelectionButtons.loggedIn
                          ? "<br>" +
                            "Contact Direction -  " +
                            feature.properties["Autorités/Contacts 1"]
                          : "")
                          */
          )
          .addTo(map);
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

export const LampadairesWorking = {
  slug: "lampadairesWorking",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change

    const data = dataSource.lampadaireInfo;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {
        const { checkbox12 } = layerSelectionButtons;

        //TBM?
        //console.log(feature.properties["probleme"]);
        // instead of using a filter, just display non-selected items in grey
        if (
          checkbox12 === "lampadairesWorking" &&
          feature.properties["probleme"] === null
        ) {
          //if (feature.properties["Type d'établissement 1"] === "Pré Scolaire") {

          return L.circleMarker(latlng, lampadaireWorkingColor);
        }
      },
      onEachFeature: function (feature, layer) {
        layer
          .bindPopup("Probleme? :  " + feature.properties["probleme"] + "<br>")
          .addTo(map);
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

export const LampadairesNotWorking = {
  slug: "lampadairesNotWorking",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.lampadaireInfo;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {
        const { checkbox13 } = layerSelectionButtons;

        //TBM?
        //console.log(feature.properties["ligne"]);
        // instead of using a filter, just display non-selected items in grey
        if (
          checkbox13 === "lampadairesNotWorking" &&
          feature.properties["probleme"] !== null
        ) {
          //if (feature.properties["Type d'établissement 1"] === "Pré Scolaire") {

          return L.circleMarker(latlng, lampadaireNotWorkingColor);
        }
      },
      onEachFeature: function (feature, layer) {
        layer
          .bindPopup("Probleme :  " + feature.properties["probleme"] + "<br>")
          .addTo(map);
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

/*****ADDING Prefectures LAYERS*****/

let bufferStyle = {
  fillColor: "yellow",
  fillOpacity: 0.4,

  color: "red",
  weight: 0,
  opacity: 1,
};

export const AddBTBuffer = {
  slug: "addBTBuffer",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.bufferInfo;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {},
      onEachFeature: function (feature, layer) {
        //console.log(feature);
        layer
          .bindPopup(
            "Préfecture :  " +
              feature.properties["prefecture"] +
              "<br>" +
              "Commune :  " +
              feature.properties["commune"] +
              "<br>"
          )
          .addTo(map);
      },
      style: function (feature) {
        return bufferStyle;
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

/*****ADDING Prefectures LAYERS*****/

let prefectureLoadStyle = {
  fillColor: "blue",
  fillOpacity: 0.05,

  color: "#FFF",
  weight: 1.5,
  opacity: 1,
};

export const AddPrefectures = {
  slug: "addPrefectures",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.prefectureInfo;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {},
      onEachFeature: function (feature, layer) {
        //console.log(feature);
      },
      style: function (feature) {
        return prefectureLoadStyle;
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

export const AddCantons = {
  slug: "addCantons",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.cantonInfo;
    console.log(data);
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {},
      onEachFeature: function (feature, layer) {
        //console.log(feature);
      },
      style: function (feature) {
        return prefectureLoadStyle;
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

/*****ADDING NEW PROJECT LAYERS*****/

let firstStyle = {
  fillColor: "#d7191c",
  fillOpacity: 0.6,

  color: "#FFF",
  weight: 1.5,
  opacity: 1,
};

let secondStyle = {
  fillColor: "#fdae61",
  fillOpacity: 0.6,

  color: "#FFF",
  weight: 1.5,
  opacity: 1,
};

let thirdStyle = {
  fillColor: "#ffffbf",
  fillOpacity: 0.6,

  color: "#FFF",
  weight: 1.5,
  opacity: 1,
};

let fourthStyle = {
  fillColor: "#a7daa0",
  fillOpacity: 0.6,

  color: "#FFF",
  weight: 1.5,
  opacity: 1,
};

let fithStyle = {
  fillColor: "#68a064",
  fillOpacity: 0.6,

  color: "#FFF",
  weight: 1.5,
  opacity: 1,
};

export const AddEduAccessEcole = {
  slug: "addEduAccessEcole",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.eduAccessEcoleInfo;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {},
      onEachFeature: function (feature, layer) {},
      style: function (feature) {
        let accessPercentage = parseInt(
          feature.properties.access_ecole_primaire_perc_acces
        );
        if (accessPercentage >= 49 && accessPercentage < 60) {
          return firstStyle;
        }
        if (accessPercentage >= 60 && accessPercentage < 70) {
          return secondStyle;
        }
        if (accessPercentage >= 70 && accessPercentage < 80) {
          return thirdStyle;
        }
        if (accessPercentage >= 80 && accessPercentage < 90) {
          return fourthStyle;
        }
        if (accessPercentage >= 90 && accessPercentage <= 100) {
          return fithStyle;
        }
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

export const AddEduEleveProf = {
  slug: "addEduEleveProf",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.eduEleveProfInfo;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {},
      onEachFeature: function (feature, layer) {
        layer
          .bindPopup("Nom :  " + feature.properties.canton + "<br>")
          .addTo(map);
      },
      style: function (feature) {
        let elevePerProf = parseInt(feature.properties.eleves_classe);
        if (elevePerProf >= 18 && elevePerProf < 25) {
          return fithStyle;
        }
        if (elevePerProf >= 25 && elevePerProf < 30) {
          return fourthStyle;
        }
        if (elevePerProf >= 30 && elevePerProf < 35) {
          return thirdStyle;
        }
        if (elevePerProf >= 35 && elevePerProf < 40) {
          return secondStyle;
        }
        if (elevePerProf >= 40 && elevePerProf <= 49) {
          return firstStyle;
        }
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

export const AddeduEleveSansBat = {
  slug: "addeduEleveSansBat",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.eduEleveSansBatInfo;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {},
      onEachFeature: function (feature, layer) {
        layer
          .bindPopup("Nom :  " + feature.properties.canton + "<br>")
          .addTo(map);
      },
      style: function (feature) {
        let eleveSansBatiment = parseInt(feature.properties.eleves_sans_bat);
        if (eleveSansBatiment === 0) {
          return fithStyle;
        }
        if (eleveSansBatiment > 0 && eleveSansBatiment < 42) {
          return fourthStyle;
        }
        if (eleveSansBatiment >= 42 && eleveSansBatiment < 91) {
          return thirdStyle;
        }
        if (eleveSansBatiment >= 91) {
          return firstStyle;
        }
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

let eduBufferStyle = {
  fillColor: "yellow",
  fillOpacity: 0.4,
  color: "#FFF",
  opacity: 0,
  weight: 0,
  radius: 5,
};

export const AddEduAccessEcoleCanton = {
  slug: "addEduAccessEcoleCanton",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.eduAccessEcoleCantonInfo;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {
        return L.circleMarker(latlng, lampadaireWorkingColor);
      },
      onEachFeature: function (feature, layer) {
        //console.log(feature);
      },
      style: function (feature) {
        return eduBufferStyle;
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

let pointEcoleColor = {
  fillColor: "#000",
  fillOpacity: 1,
  color: "#000",
  weight: 1,
  opacity: 1,
  radius: 3,
};
export const AddEduEcolePoint = {
  slug: "addEduEcolePoint",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change

    const data = dataSource.eduEcolePointInfo;
    console.log(dataSource);
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {
        return L.circleMarker(latlng, pointEcoleColor);
      },
      onEachFeature: function (feature, layer) {
        //console.log(feature);
        layer
          .bindPopup(
            "Nom :  " +
              feature.properties["etablissement_nom"] +
              "<br>" +
              "Toilettes : " +
              feature.properties["toilettes"] +
              "<br>" +
              "Lampadaires : " +
              feature.properties["lampadaire"] +
              "<br>" +
              "Accès à l'électricité : " +
              feature.properties["elec_acces"] +
              "<br>" +
              "Canton : " +
              feature.properties["canton"] +
              "<br>"
          )
          .addTo(map);
      },
      style: function (feature) {},
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

export const AddSchoolWithoutToilets = {
  slug: "ecoles_sans_toilettes",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.ecolesSansToilettes;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {
        return L.circleMarker(latlng, {
          color: "#ba1b1b",
          weight: 1,
          opacity: 1,
          fillOpacity: 1,
          radius: 2,
        });
      },
      onEachFeature: function (feature, layer) {
        //console.log(feature);
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

export const AddSchoolWithoutElectricityLightning = {
  slug: "ecoles_sans_electricite_eclairage",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.ecolesSansElectriciteEclairageInfo;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {
        return L.circleMarker(latlng, {
          color: "#ba1b1b",
          weight: 1,
          opacity: 1,
          fillOpacity: 1,
          radius: 2,
        });
      },
      onEachFeature: function (feature, layer) {
        //console.log(feature);
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

export const AddVolontaryTeachers = {
  slug: "professeurs_volontaires_perc",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.professeursVolontaires;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {},
      onEachFeature: function (feature, layer) {
        //console.log(feature);
      },
      style: function (feature) {
        let style = {
          fillOpacity: 0.6,

          color: "#FFF",
          weight: 1.5,
          opacity: 1,
        };
        let percentage = parseInt(feature.properties.prof_vol_perc);
        if (percentage >= 0 && percentage < 20) {
          style.fillColor = "#fff5f0";
          return style;
        }
        if (percentage >= 20 && percentage < 40) {
          style.fillColor = "#fca487";
          return style;
        }
        if (percentage >= 40 && percentage < 60) {
          style.fillColor = "#ed392b";
          return style;
        }
        if (percentage >= 60 && percentage < 65) {
          style.fillColor = "#ba1b1b";
          return style;
        }
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};

export const AddPopulation = {
  slug: "population",
  forceUpdate: true,
  addToMap: (map, dataSource, layerSelectionButtons) => {
    //TBM really not clear, why we do dataSource.educationInfo --> need change
    const data = dataSource.populationInfo;
    let myLayer = L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {},
      onEachFeature: function (feature, layer) {
        //console.log(feature);
      },
      style: function (feature) {
        let style = {
          fillOpacity: 0.8,

          color: "#FFF",
          weight: 1.5,
          opacity: 1,
        };
        let value = parseInt(feature.properties.Population_sum);
        if (value >= 0 && value < 21) {
          style.fillColor = "#440154";
          return style;
        }
        if (value >= 21 && value < 53) {
          style.fillColor = "#3a528b";
          return style;
        }
        if (value >= 53 && value < 127) {
          style.fillColor = "#20908d";
          return style;
        }
        if (value >= 127 && value < 282) {
          style.fillColor = "#5dc962";
          return style;
        }
        if (value >= 282 && value <= 10000) {
          style.fillColor = "#fde725";
          return style;
        }
      },
    });
    myLayer.addTo(map);
    return myLayer;
  },
};
