import React from "react";
import "./education.scss";
import SideMenu from "../../side-menu/sideMenu";
import analyseIcon from "../../../../assets/images/icons/analyse.svg";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const Education = () => {
  const [educationLevel, setEducationLevel] = React.useState("");

  const handleChangeEducationLevel = (event) => {
    setEducationLevel(event.target.value);
  };

  const [state, setState] = React.useState("");

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div className="education-container">
      <div className="education-container-menu">
        <SideMenu />
      </div>
      <div className="education-container-sidebar">
        <div className="education-container-sidebar-title">
          <h2>
            <img src={analyseIcon} alt="icon" />
            <span>Analyse</span>
          </h2>
        </div>
        <p>Praesent semper feugiat nibh sed pulvinar proin.</p>
        <p>Praesent semper feugiat nibh sed pulvinar proin. </p>
        <div className="education-container-sidebar-list">
          <div className="education-container-sidebar-list-item">
            <div className="education-container-sidebar-list-item-placeholder">
              <h4>placeholder</h4>
            </div>
            <div className="education-container-sidebar-list-item-text">
              <h5>Éducation</h5>
              <p>
                Visualisez l'accessibilité des réseaux électriques depuis les
                écoles.
              </p>
            </div>
          </div>
        </div>
        <div className="education-container-sidebar-filter">
          <div className="education-container-sidebar-filter-item">
            <ul>
              <li>
                <button>Régions</button>
              </li>
              <li>
                <button>Commune</button>
              </li>
            </ul>
          </div>
          <div className="education-container-sidebar-filter-item">
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-label">
                Catégories d'établissement
              </InputLabel>
              <Select
                labelId="education-level-select-label"
                id="demo-simple-select"
                value={educationLevel}
                label="Catégories d'établissement"
                onChange={handleChangeEducationLevel}
              >
                <MenuItem value="universite">Université</MenuItem>
                <MenuItem value="lycee">Lycée</MenuItem>
                <MenuItem value="college">Collège</MenuItem>
                <MenuItem value="primaire">Primaire</MenuItem>
                <MenuItem value="maternalle">Maternelle</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="education-container-sidebar-filter-item">
            <div className="education-container-sidebar-filter-item-checklist">
              <div className="education-container-sidebar-filter-item-checklist-item">
                <p>Couverture</p>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange}
                      name="Radius d’accessibilité"
                    />
                  }
                  label="Radius d’accessibilité"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange}
                      name="Zones non desservis"
                    />
                  }
                  label="Zones non desservis"
                />
              </div>
              <div className="education-container-sidebar-filter-item-checklist-item">
                <p>Établissements scolaires</p>
                <FormControlLabel
                  control={
                    <Checkbox onChange={handleChange} name="Préscolaire" />
                  }
                  label="Préscolaire"
                />
                <FormControlLabel
                  control={<Checkbox onChange={handleChange} name="Primaire" />}
                  label="Primaire"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange}
                      name="Secondaire I et II"
                    />
                  }
                  label="Secondaire I et II"
                />
              </div>
              <div className="education-container-sidebar-filter-item-checklist-item">
                <p>Bâtiments</p>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange}
                      name="Dans le radius d’accessibilité"
                    />
                  }
                  label="Dans le radius d’accessibilité"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange}
                      name="Hors du radius d’accessibilité"
                    />
                  }
                  label="Hors du radius d’accessibilité"
                />
              </div>
            </div>
            <div className="education-container-sidebar-filter-item-quality">
              <p>Qualité</p>
            </div>
          </div>
        </div>
      </div>
      <div className="education-container-map"></div>
    </div>
  );
};

export default Education;
