import React, { useState, useEffect } from "react";
import "./AreaBlock.scss";
import LocationInfo from "../../../assets/images/location-icon.svg";
import LocationInfoGreen from "../../../assets/images/location-icon-green.svg";
import CancelIcon from "../../../assets/images/highlight_off.svg";
import Accessibility from "../accessibility/Accessibility";
//import { accessibility } from "../../../assets/constants/const";
//import { sectorsList } from "../../../assets/constants/const";
import { selectedZoneData } from "../../../services/recapService";

const AreaInfo = ({
  subdivisionLevel,
  selectedRegion,
  selectedPrefecture,
  selectedCommune,
  setSelectedRegion,
  setSelectedPrefecture,
  setSelectedCommune,
}) => {
  
  const [selectedData, setSelectedData] = useState({});

  const closeOpenedSubdivision = () => {
    setSelectedRegion(0);
    setSelectedPrefecture(0);
    setSelectedCommune(0);
  };

  const getSelectedData = () => {
    let subDivisionId = "";
    if (subdivisionLevel === "region") subDivisionId = selectedRegion;
    if (subdivisionLevel === "prefecture") subDivisionId = selectedPrefecture;
    if (subdivisionLevel === "commune") subDivisionId = selectedCommune;
    selectedZoneData(subdivisionLevel, subDivisionId).then((res) => {
      setSelectedData(res);
    });
  };

  useEffect(() => {
    getSelectedData();
  },
  // eslint-disable-next-line
   [subdivisionLevel, selectedRegion, selectedPrefecture, selectedCommune]);

  /*useEffect(
      getSelectedData,
      []
    );*/

  //const selectedData = useRef({});
  /*
      selectedZoneData(subdivisionLevel,selectedRegion).then((res)=>{
        setSelectedData(res);
      });
     */
  console.log("subdivisionLevel: " + subdivisionLevel);
  console.log("selectedRegion: " + selectedRegion);
  console.log("selectedPrefecture: " + selectedPrefecture);
  console.log("selectedCommune: " + selectedCommune);

  /* Code a ne pas enlever sinon le area block ne marchera pas sur responsive */
  const [close, setClose] = useState(false);

  useEffect(() => {
    if (
      (subdivisionLevel === "region" && selectedRegion === 0) ||
      (subdivisionLevel === "prefecture" && selectedPrefecture === 0) ||
      (subdivisionLevel === "commune" && selectedCommune === 0)
    ) {
      setClose(true);
      console.log(close);
    } else {
      setClose(false);
      console.log(close);
    }
  },
  // eslint-disable-next-line
   [selectedRegion, selectedPrefecture, selectedCommune]);

  return (
    <div className={"area-block " + (close === true ? "" : "open")}>
      {(subdivisionLevel === "region" && selectedRegion === 0) ||
      (subdivisionLevel === "prefecture" && selectedPrefecture === 0) ||
      (subdivisionLevel === "commune" && selectedCommune === 0) ? (
        <div className="area-block-select-area">
          <img className="green" src={LocationInfoGreen} alt="location icon" />
          <img className="purple" src={LocationInfo} alt="location icon" />
          <p>Veuillez sélectionner un nouvel élément sur la carte</p>
        </div>
      ) : (
        <div className="area-block-wrapper">
          <div className="area-block-wrapper-info">
            <div className="area-block-wrapper-info-header">
              <div className="area-block-wrapper-info-header-title">
                {subdivisionLevel === "region" ? (
                  <ul>
                    <li key="region{selectedData.id}">{selectedData.name}</li>
                  </ul>
                ) : (
                  ""
                )}

                {subdivisionLevel === "prefecture" ? (
                  <ul>
                    <li key="region{selectedData.region_id}">
                      {selectedData.region_name}&nbsp;
                    </li>
                    &nbsp;
                    <li key="prefecture{selectedData.id}">
                      Préfecture: {selectedData.name}
                    </li>
                  </ul>
                ) : (
                  ""
                )}

                {subdivisionLevel === "commune" ? (
                  <ul>
                    <li key="region{selectedData.region_id}">
                      {selectedData.region_name}&nbsp;
                    </li>
                    &nbsp;
                    <li key="prefecture{selectedData.prefecture_id}">
                      Préfecture: {selectedData.prefecture_name}&nbsp;
                    </li>
                    &nbsp;
                    <li key="commune{selectedData.id}">
                      Commune: {selectedData.name}
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </div>
              <div
                className="area-block-wrapper-info-header-close-window"
                onClick={() => closeOpenedSubdivision()}
              >
                <p>Fermer</p>
                <img src={CancelIcon} alt="cancel icon" />
              </div>
            </div>
          </div>
          <div className="area-block-wrapper-body">
            <div className="area-block-wrapper-body-item">
              <h3>Programme</h3>
              <ul>
                <li>Démarrage :</li>
                <li>10/10/2021</li>
              </ul>
              <ul>
                <li>Fin :</li>
                <li>10/06/2022</li>
              </ul>
            </div>
            {/*
                        <div className="area-block-wrapper-body-item">
                            <h3>Accessibilité</h3>
                            <div className="area-block-wrapper-body-item-accessibility">
                                {
                                    accessibility.map((access) => {
                                        return (
                                            <Accessibility text={access.month} status={access.status} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                      */}
            <div className="area-block-wrapper-body-item">
              <h3>Progrès par secteur</h3>
              <div className="area-block-wrapper-body-item-progress">
                {selectedData.sectors &&
                  selectedData.sectors.map((access) => {
                    return (
                      <Accessibility
                        text={access.name}
                        status={access.sectorStatus}
                        color={access.sectorColor}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="area-block-wrapper-legend">
            <p>Légende</p>
            <div className="area-block-wrapper-legend-block">
              <ul className="area-block-wrapper-legend-block-item">
                <li>
                  <span></span>
                </li>
                <li>
                  <span>A lancer</span>
                </li>
              </ul>
              <ul className="area-block-wrapper-legend-block-item">
                <li>
                  <span></span>
                </li>
                <li>
                  <span>En cours</span>
                </li>
              </ul>
              <ul className="area-block-wrapper-legend-block-item">
                <li>
                  <span></span>
                </li>
                <li>
                  <span>En finalisation</span>
                </li>
              </ul>
              <ul className="area-block-wrapper-legend-block-item">
                <li>
                  <span></span>
                </li>
                <li>
                  <span>Terminé</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AreaInfo;
