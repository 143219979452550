import React from 'react';
import './MitsioMotu.scss';
import Header from "../../page_sections/header/Header";
import Footer from "../../page_sections/footer/Footer";
import mitso from "../../../assets/images/Mitsio-Motu-logo.png";

const MitsioMotu = () => {
    return (
        <div>
            <Header />
            <div className="mitsio-motu-container">
                <div className="mitsio-motu-container-inner">
                    <div className="mitsio-motu-container-inner-left">
                        <img src={mitso} alt="logo" />
                    </div>
                    <div className="mitsio-motu-container-inner-right">
                        <h1>A propos Mitsio Motu</h1>
                        <p>Mitsio Motu est une société spécialisée dans la collecte et l’analyse de données dans le domaine des services essentiels et sociaux, active dans 7 pays d’Afrique de l’Ouest depuis 2018 et notamment au Togo, pays dans lequel la Société a participé à plusieurs projets liés aux infrastructures d’éclairage public, de connectivité et d’Accès à l’énergie. Mitsio Motu opère depuis Paris en France et Lagos au Nigeria.

</p>
                        
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default MitsioMotu;
