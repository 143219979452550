import React from 'react';
import { Redirect } from 'react-router-dom';

function PrivateRoute(props) {
    let Component = props.component;
    let userInfos = JSON.parse(localStorage.getItem('user'));
    return userInfos && userInfos.USER_ROLE === "ROLE_ADMIN" ? (
        <Component {...props} />
    ) : (
        <Redirect to={"/tableau-de-bord"} />
    );
}

export default PrivateRoute;