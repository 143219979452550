import axios from "axios";
import { apiUrl } from "../utils/axios";
import { regionsList, prefecturesList, communesList } from "./regionService";
import { sectorsList } from "./sectorService";

var allRegions = [];
var allPrefectures = [];
var allCommunes = [];
var allStatus = [];
var allSectors = [];
var numberOfSectors = 25;

export const recapData = async () => {
  const { data } = await axios.get(`${apiUrl}status`);
  return data;
};

export const regionRecap = async () => {
  // @Kwaku: not sure it's the best place to make these calls, as it may result in error, if we don't charge regions first
  allRegions = await regionsList();
  allPrefectures = await prefecturesList();
  allCommunes = await communesList();
  allSectors = await sectorsList();
  allStatus = await recapData();

  for (let i = 0; i < allRegions.length; i++) {
    allRegions[i].sectors = [];
    allRegions[i].generalStatus = {};
    let regionTotalPercent = 0;
    let regionGenData = {};
    for (let j = 0; j < allSectors.length; j++) {
      let nbCantonsToBeStarted = 0;
      let nbCantonsStarted = 0;
      let nbCantonsInFinalisation = 0;
      let nbCantonsFinished = 0;
      let sectorData = {};
      sectorData.id = allSectors[j].id;
      sectorData.name = allSectors[j].name;
      // eslint-disable-next-line
      allStatus.forEach((item) => {
        if (
          allRegions[i].id === item.region_id &&
          allSectors[j].id === item.secteur_id
        ) {
          if (item.status === 0) {
            nbCantonsToBeStarted++;
          } else if (item.status === 1) {
            nbCantonsStarted++;
          } else if (item.status === 2) {
            nbCantonsInFinalisation++;
          } else if (item.status === 3) {
            nbCantonsFinished++;
          }
        }
      });
      let tempPercent = await getSectorAdvancementPercent(
        nbCantonsToBeStarted,
        nbCantonsStarted,
        nbCantonsInFinalisation,
        nbCantonsFinished
      );
      sectorData.sectorPercent = tempPercent;
      regionTotalPercent = regionTotalPercent + tempPercent;
      if (tempPercent === 0) {
        sectorData.sectorStatus = "A lancer";
        sectorData.sectorColor = {
          "background-color": "#ededed",
        };
      }
      if (tempPercent > 0 && tempPercent < 80) {
        sectorData.sectorStatus = "En cours";
        sectorData.sectorColor = {
          "background-color": "#d0f6eb",
        };
      }
      if (tempPercent >= 80 && tempPercent < 100) {
        sectorData.sectorStatus = "En finalisation";
        sectorData.sectorColor = {
          "background-color": "#3bd9a9",
        };
      }
      if (tempPercent === 100) {
        sectorData.sectorStatus = "Terminé";
        sectorData.sectorColor = {
          "background-color": "#187C5E",
        };
      }
      allRegions[i].sectors.push(sectorData);
    }

    // Here we make the Area general percentage calculation
    let regionGenAdvancementPercent = regionTotalPercent/numberOfSectors;
    if (regionGenAdvancementPercent === 0) {
      regionGenData.advancementStatus = "A lancer";
      regionGenData.sectorColor = {
        "background-color": "#ededed",
      };
    }
    if (regionGenAdvancementPercent > 0 && regionGenAdvancementPercent < 80) {
      regionGenData.advancementStatus = "En cours";
      regionGenData.sectorColor = {
        "background-color": "#d0f6eb",
      };
    }
    if (regionGenAdvancementPercent >= 80 && regionGenAdvancementPercent < 100) {
      regionGenData.advancementStatus = "En finalisation";
      regionGenData.sectorColor = {
        "background-color": "#3bd9a9",
      };
    }
    if (regionGenAdvancementPercent === 100) {
      regionGenData.advancementStatus = "Terminé";
      regionGenData.sectorColor = {
        "background-color": "#187C5E",
      };
    }
    allRegions[i].generalStatus = regionGenData;


  }
  //regionsRecaps = allRegions;
  await prefectureRecap();
  await communeRecap();
  return allRegions;
};

export const prefectureRecap = async () => {
  for (let i = 0; i < allPrefectures.length; i++) {
    allPrefectures[i].sectors = [];
    allPrefectures[i].generalStatus = {};
    let prefectureTotalPercent = 0;
    let prefectureGenData = {};
    for (let j = 0; j < allSectors.length; j++) {
      let nbCantonsToBeStarted = 0;
      let nbCantonsStarted = 0;
      let nbCantonsInFinalisation = 0;
      let nbCantonsFinished = 0;
      let sectorData = {};
      sectorData.id = allSectors[j].id;
      // eslint-disable-next-line
      sectorData.name = allSectors[j].name;
      // eslint-disable-next-line
      allStatus.forEach((item) => {
        if (
          allPrefectures[i].id === item.prefecture_id &&
          allSectors[j].id === item.secteur_id
        ) {
          if (item.status === 0) {
            nbCantonsToBeStarted++;
          } else if (item.status === 1) {
            nbCantonsStarted++;
          } else if (item.status === 2) {
            nbCantonsInFinalisation++;
          } else if (item.status === 3) {
            nbCantonsFinished++;
          }
        }
      });
      let tempPercent = await getSectorAdvancementPercent(
        nbCantonsToBeStarted,
        nbCantonsStarted,
        nbCantonsInFinalisation,
        nbCantonsFinished
      );
      sectorData.sectorPercent = tempPercent;
      prefectureTotalPercent = prefectureTotalPercent + tempPercent;
      if (tempPercent === 0) {
        sectorData.sectorStatus = "A lancer";
        sectorData.sectorColor = {
          "background-color": "#ededed",
        };
      }
      if (tempPercent > 0 && tempPercent < 80) {
        sectorData.sectorStatus = "En cours";
        sectorData.sectorColor = {
          "background-color": "#d0f6eb",
        };
      }
      if (tempPercent >= 80 && tempPercent < 100) {
        sectorData.sectorStatus = "En finalisation";
        sectorData.sectorColor = {
          "background-color": "#3bd9a9",
        };
      }
      if (tempPercent === 100) {
        sectorData.sectorStatus = "Terminé";
        sectorData.sectorColor = {
          "background-color": "#187C5E",
        };
      }
      allPrefectures[i].sectors.push(sectorData);
    }
    // Here we make the Area general percentage calculation
    let prefectureGenAdvancementPercent = prefectureTotalPercent/numberOfSectors;
    if (prefectureGenAdvancementPercent === 0) {
      prefectureGenData.advancementStatus = "A lancer";
      prefectureGenData.sectorColor = {
        "background-color": "#ededed",
      };
    }
    if (prefectureGenAdvancementPercent > 0 && prefectureGenAdvancementPercent < 80) {
      prefectureGenData.advancementStatus = "En cours";
      prefectureGenData.sectorColor = {
        "background-color": "#d0f6eb",
      };
    }
    if (prefectureGenAdvancementPercent >= 80 && prefectureGenAdvancementPercent < 100) {
      prefectureGenData.advancementStatus = "En finalisation";
      prefectureGenData.sectorColor = {
        "background-color": "#3bd9a9",
      };
    }
    if (prefectureGenAdvancementPercent === 100) {
      prefectureGenData.advancementStatus = "Terminé";
      prefectureGenData.sectorColor = {
        "background-color": "#187C5E",
      };
    }
    allPrefectures[i].generalStatus = prefectureGenData;
  }
  return allPrefectures;
};

export const communeRecap = async () => {
  for (let i = 0; i < allCommunes.length; i++) {
    allCommunes[i].sectors = [];
    allCommunes[i].generalStatus = {};
    let communeTotalPercent = 0;
    let communeGenData = {};
    for (let j = 0; j < allSectors.length; j++) {
      let nbCantonsToBeStarted = 0;
      let nbCantonsStarted = 0;
      let nbCantonsInFinalisation = 0;
      let nbCantonsFinished = 0;
      let sectorData = {};
      sectorData.id = allSectors[j].id;
      sectorData.name = allSectors[j].name;
      // eslint-disable-next-line
      allStatus.forEach((item) => {
        if (
          allCommunes[i].id === item.commune_id &&
          allSectors[j].id === item.secteur_id
        ) {
          if (item.status === 0) {
            nbCantonsToBeStarted++;
          } else if (item.status === 1) {
            nbCantonsStarted++;
          } else if (item.status === 2) {
            nbCantonsInFinalisation++;
          } else if (item.status === 3) {
            nbCantonsFinished++;
          }
        }
      });
      let tempPercent = await getSectorAdvancementPercent(
        nbCantonsToBeStarted,
        nbCantonsStarted,
        nbCantonsInFinalisation,
        nbCantonsFinished
      );
      sectorData.sectorPercent = tempPercent;
      communeTotalPercent = communeTotalPercent + tempPercent;
      if (tempPercent === 0) {
        sectorData.sectorStatus = "A lancer";
        sectorData.sectorColor = {
          "background-color": "#ededed",
        };
      }
      if (tempPercent > 0 && tempPercent < 80) {
        sectorData.sectorStatus = "En cours";
        sectorData.sectorColor = {
          "background-color": "#d0f6eb",
        };
      }
      if (tempPercent >= 80 && tempPercent < 100) {
        sectorData.sectorStatus = "En finalisation";
        sectorData.sectorColor = {
          "background-color": "#3bd9a9",
        };
      }
      if (tempPercent === 100) {
        sectorData.sectorStatus = "Terminé";
        sectorData.sectorColor = {
          "background-color": "#187C5E",
        };
      }
      allCommunes[i].sectors.push(sectorData);
    }

    // Here we make the Area general percentage calculation
    let communeGenAdvancementPercent = communeTotalPercent/numberOfSectors;
    if (communeGenAdvancementPercent === 0) {
      communeGenData.advancementStatus = "A lancer";
      communeGenData.sectorColor = {
        "background-color": "#ededed",
      };
    }
    if (communeGenAdvancementPercent > 0 && communeGenAdvancementPercent < 80) {
      communeGenData.advancementStatus = "En cours";
      communeGenData.sectorColor = {
        "background-color": "#d0f6eb",
      };
    }
    if (communeGenAdvancementPercent >= 80 && communeGenAdvancementPercent < 100) {
      communeGenData.advancementStatus = "En finalisation";
      communeGenData.sectorColor = {
        "background-color": "#3bd9a9",
      };
    }
    if (communeGenAdvancementPercent === 100) {
      communeGenData.advancementStatus = "Terminé";
      communeGenData.sectorColor = {
        "background-color": "#187C5E",
      };
    }
    allCommunes[i].generalStatus = communeGenData;
  }

  return allCommunes;
};

const getSectorAdvancementPercent = async (
  nbCantonsToBeStarted,
  nbCantonsStarted,
  nbCantonsInFinalisation,
  nbCantonsFinished
) => {
  let nbCantons =
    nbCantonsToBeStarted +
    nbCantonsStarted +
    nbCantonsInFinalisation +
    nbCantonsFinished;
  let percent =
    ((nbCantonsToBeStarted * 0 +
      nbCantonsStarted * 0.4 +
      nbCantonsInFinalisation * 0.8 +
      nbCantonsFinished * 1) *
      100) /
    nbCantons;
  return Math.round(percent);
};

export const selectedZoneData = async (subDivisionLevel, subDivisionId) => {
  let returnedData = {};
  console.log(subDivisionLevel + " --- " + subDivisionId);
  if (subDivisionLevel === "region") {
    allRegions.forEach((item) => {
      if (item.id === subDivisionId) {
        returnedData = item;
      }
    });
  }

  if (subDivisionLevel === "prefecture") {
    allPrefectures.forEach((item) => {
      if (item.id === subDivisionId) {
        returnedData = item;
      }
    });
  }

  if (subDivisionLevel === "commune") {
    allCommunes.forEach((item) => {
      if (item.id === subDivisionId) {
        returnedData = item;
      }
    });
  }

  return returnedData;
};


export const selectedSectorData = async (subDivisionLevel, selectedSector) => {
  if (subDivisionLevel === "region") {
    return allRegions;
  }

  if (subDivisionLevel === "prefecture") {
    return allPrefectures;
  }

  if (subDivisionLevel === "commune") {

    return allCommunes;
  }
};
