import React from 'react';
import './Project.scss';
import Header from "../../page_sections/header/Header";
import Footer from "../../page_sections/footer/Footer";
import togo from "../../../assets/images/Togo-logo.png";

const Project = () => {
    return (
        <div>
            <Header />
            <div className="project-container">
                <div className="project-container-inner">
                    <div className="project-container-inner-header">
                        <img src={togo} alt="logo"/>
                    </div>
                    <div className="project-container-inner-body">
                        <h1>Projet de recencement des infrastructures sociales et économiques </h1>
                        
                        <h3>L’administration territoriale – augmentée par la donnée et la cartographie</h3>
                        <p>
                            Le géoportail des infrastructures sociales et économiques de la République Togolaise permet Togolaise l’accès et la visualisation de données précises concernant l’état actuel des infrastructures socio-économiques dans la République Togolaise.
                        </p>
                        <p>
                            Ces données sont actualisées et analysées pour permettre une planification et une optimisation des réseaux d’infrastructures.
                        </p>
                        <h3>Contexte</h3>
                        <p>
                            L’opérationnalisation des projets prioritaires de la Feuille de route 2020-2025 du gouvernement nécessite un déploiement optimal et efficient d’infrastructures socio-économiques dans les secteurs clés de développement notamment l’éducation, la santé, l’administration territoriale, les transports, mines et énergie, l’eau, les télécommunications, l’agriculture, l’environnement.
                        </p>
                        <p>
                            Dans ce cadre-là, le Ministère délégué en charge du Développement des Territoires (MDDT) a porté en partenariat avec le Ministère de l’Economie Numérique et de la Transition Digitale (MENTD) et le Ministère de la Planification et du Développement (MPD) ainsi que tous les Ministères de la République Togolaises un projet de recensement national et de cartographie des infrastructures dans les secteurs prioritaires susmentionnés.
                        </p>
                        <p>
                            Ce projet a permis suite au recensement le développement d’une plateforme intégrée pour l’analyse et l’utilisation de ces données, notamment dans le cadre de la planification des infrastructures sociales et économiques de la République Togolaise.
                        </p>
                        <h3>
                            Le Prestataire
                        </h3>
                        <p>
                            Mitsio Motu est une société spécialisée dans la collecte et l’analyse de données dans le domaine des services essentiels et sociaux.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Project;
