import React, {useEffect, useState} from 'react';
import './water.scss';
import LoadDataTask from "../../../../services/cartes/LoadDataTask";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormControlLabel from "@mui/material/FormControlLabel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@mui/material/Autocomplete";
import {baseLayers, countyPositions, regionsPrefectureList} from "../../../../assets/constants/const";
import TextField from "@mui/material/TextField";
import Map from "../../../carte-pages/carte-map/Mapt";
import eau from "../../../../assets/images/eau1.jpg";

import {
    dataLoadInfos,
    EducationUniversite,
    EducationLycee,
    EducationCollege,
    EducationPrimaire,
    EducationJardin,
    EcoleElectrifiee,
    EcoleNonElectrifiee,
    LigneBT,
    LigneMT,
    PoteauBT,
    PoteauMT,
    LampadairesWorking,
    LampadairesNotWorking,
    AddPrefectures,
    AddCantons,
    AddBTBuffer,
    AddRayonCouverture,
    AddPointEau,
    AddHabitantParPoint,
    AddPopCouverte,

} from "./layers";
import SideMenu from "../../side-menu/sideMenu";
import analyseIcon from "../../../../assets/images/icons/analyse.svg";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
//import sante from "../../../../assets/images/santé.png";

const Water = () => {

    const [subdivisionLevel, setSubdivisionLevel] = useState("canton");
    const [selectedRegion, setSelectedRegion] = useState(0);
    const [selectedPrefecture, setSelectedPrefecture] = useState(0);
    const [selectedCommune, setSelectedCommune] = useState(0);
    //const [selectedLayer, setSelectedLayer] = useState(0);

    const [regionSelected, setRegionSelected] = useState(null);
    const [regionSelectedPrefectures, setRegionSelectedPrefectures] = useState(null);
    //const [prefectureSelected, setPrefectureSelected] = useState(null);

    //const [selectedPref, setSelectedPref] = useState(-1);

    const [mapData, setMapData] = useState({});
    const [selectedCounty, setSelectedCounty] = useState(0);
    const [radioVal, setRadioVal] = useState("type");
    const [checkbox0, setCheckbox0] = useState("canton");
    const [checkbox20, setCheckbox20] = useState("");
    const [checkbox30, setCheckbox30] = useState("");
    const [checkbox31, setCheckbox31] = useState("");
    const [checkbox1, setCheckbox1] = useState("");
    const [checkbox2, setCheckbox2] = useState("");
    const [checkbox3, setCheckbox3] = useState("");
    const [checkbox4, setCheckbox4] = useState("");
    const [checkbox5, setCheckbox5] = useState("");
    const [checkbox6, setCheckbox6] = useState("");
    const [checkbox7, setCheckbox7] = useState("");
    const [checkbox8, setCheckbox8] = useState("");
    const [checkbox9, setCheckbox9] = useState("");
    const [checkbox10, setCheckbox10] = useState("");
    const [checkbox11, setCheckbox11] = useState("");
    const [checkbox12, setCheckbox12] = useState("");
    const [checkbox13, setCheckbox13] = useState("");

    // here the states for the new project

    const [radioEau, setRadioEau] = useState("");
    const [catonButtonClicked, setCatonButtonClicked] = useState(false);

    const layers = [
        EducationUniversite,
        EducationLycee,
        EducationCollege,
        EducationPrimaire,
        EducationJardin,
        EcoleElectrifiee,
        EcoleNonElectrifiee,
        LampadairesWorking,
        LampadairesNotWorking,
        LigneBT,
        LigneMT,
        PoteauBT,
        PoteauMT,
        AddPrefectures,
        AddCantons,
        AddBTBuffer,
        AddRayonCouverture,
        AddPointEau,
        AddHabitantParPoint,
        AddPopCouverte,
    ];

    const layerSelectionButtons = {
        radioVal,
        setRadioVal,
        checkbox1,
        setCheckbox1,
        checkbox2,
        setCheckbox2,
        checkbox3,
        setCheckbox3,
        checkbox4,
        setCheckbox4,
        checkbox5,
        setCheckbox5,
        checkbox6,
        setCheckbox6,
        checkbox7,
        setCheckbox7,
        checkbox8,
        setCheckbox8,
        checkbox9,
        setCheckbox9,
        checkbox10,
        setCheckbox10,
        checkbox11,
        setCheckbox11,
        checkbox12,
        setCheckbox12,
        checkbox13,
        setCheckbox13,
        checkbox0,
        setCheckbox0,
        checkbox20,
        setCheckbox20,
        checkbox30,
        setCheckbox30,
        checkbox31,
        setCheckbox31
    };


    const displayedLayers = {
        [AddCantons.slug]: checkbox0,
        [AddBTBuffer.slug]: checkbox20,
        [PoteauBT.slug]: checkbox1,
        [PoteauMT.slug]: checkbox2,
        //[PoteauHT.slug]: checkbox3,
        [LigneBT.slug]: checkbox4,
        [LigneMT.slug]: checkbox5,
        //[LigneHT.slug]: checkbox6,
        [AddBTBuffer.slug]: checkbox20,
        [EducationUniversite.slug]: checkbox7,
        [EducationLycee.slug]: checkbox8,
        [EducationCollege.slug]: checkbox9,
        [EducationPrimaire.slug]: checkbox10,
        [EducationJardin.slug]: checkbox11,
        [LampadairesWorking.slug]: checkbox12,
        [LampadairesNotWorking.slug]: checkbox13,
        [EcoleElectrifiee.slug]: checkbox30,
        [EcoleNonElectrifiee.slug]: checkbox31,
        [AddHabitantParPoint.slug]: radioEau === "habitant_par_point",
        [AddPointEau.slug]: catonButtonClicked === true,
        [AddPopCouverte.slug]: radioEau === "pop_couverte",
        [AddRayonCouverture.slug]: catonButtonClicked === true,

    };

    /* BELOW, the functions don't change for any sector */

    const loadData = function () {

        let mounted = { val: true };
        const loadDataTask = new LoadDataTask();
        dataLoadInfos.forEach((info) => {
            loadDataTask.load(info, mounted, setMapData);

        });
        /*sharedDataLoadInfos.forEach((info) => {
          loadDataTask.load(info, mounted, setMapData);
        });*/
        console.log(mapData);
        return () => (mounted.val = false);
    };

    /*const checkDataAvailable = () => {
        let dataAvailableFlag = true;
        dataLoadInfos.forEach((info) => {
            if (!mapData[info.fieldName]) dataAvailableFlag = false;
        });

        return dataAvailableFlag;
    };*/
/*
    const addLayerToMap = (event) => {
        //*** Poteaux layers *********
        if(event.target.checked === true &&  event.target.defaultValue === "poteauBT"){
            //setSelectedLayer("poteauBT");
            setCheckbox1("poteauBT");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "poteauBT"){
            //setSelectedLayer("");
            setCheckbox1("");
        }
        //----End


        if(event.target.checked === true &&  event.target.defaultValue === "poteauMT"){
            //setSelectedLayer("poteauMT");
            //setRadioVal("type");
            setCheckbox2("poteauMT");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "poteauMT"){
            //setSelectedLayer("");
            setCheckbox2("");
        }
        //----End


        if(event.target.checked === true &&  event.target.defaultValue === "poteauHT"){
            //setSelectedLayer("poteauHT");
            //setRadioVal("type");
            setCheckbox3("poteauHT");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "poteauHT"){
            //setSelectedLayer("");
            setCheckbox3("");
        }
        //----End


        //*** Lignes layers *********
        if(event.target.checked === true &&  event.target.defaultValue === "ligneBT"){
            //setSelectedLayer("ligneBT");
            //setRadioVal("type");
            setCheckbox4("ligneBT");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "ligneBT"){
            //setSelectedLayer("");
            setCheckbox4("");
        }
        //----End

        if(event.target.checked === true &&  event.target.defaultValue === "ligneMT"){
            //setSelectedLayer("ligneMT");
            //setRadioVal("type");
            setCheckbox5("ligneMT");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "ligneMT"){
            //setSelectedLayer("");
            setCheckbox5("");
        }
        //----End

        if(event.target.checked === true &&  event.target.defaultValue === "ligneHT"){
            //setSelectedLayer("ligneHT");
            //setRadioVal("type");
            setCheckbox6("ligneHT");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "ligneHT"){
            //setSelectedLayer("");
            setCheckbox6("");
        }
        //----End

        if(event.target.checked === true &&  event.target.defaultValue === "ligneBTBuffer"){
            //setSelectedLayer("ligneHT");
            //setRadioVal("type");
            setCheckbox20("ligneBTBuffer");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "ligneBTBuffer"){
            //setSelectedLayer("");
            setCheckbox20("");
        }
        //----End


        //*** Education layers *********
        if(event.target.checked === true &&  event.target.defaultValue === "universite"){
            //setSelectedLayer("universite");
            //setRadioVal("type");
            setCheckbox7("universite");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "universite"){
            //setSelectedLayer("");
            setCheckbox7("");
        }
        //----End

        if(event.target.checked === true &&  event.target.defaultValue === "lycee"){
            //setSelectedLayer("lycee");
            //setRadioVal("type");
            setCheckbox8("lycee");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "lycee"){
            //setSelectedLayer("");
            setCheckbox8("");
        }
        //----End

        if(event.target.checked === true &&  event.target.defaultValue === "college"){
            //setSelectedLayer("college");
            //setRadioVal("type");
            setCheckbox9("college");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "college"){
            //setSelectedLayer("");
            setCheckbox9("");
        }
        //----End

        if(event.target.checked === true &&  event.target.defaultValue === "ecole_primaire"){
            //setSelectedLayer("ecole_primaire");
            //setRadioVal("type");
            setCheckbox10("ecole_primaire");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "ecole_primaire"){
            //setSelectedLayer("");
            setCheckbox10("");
        }
        //----End


        if(event.target.checked === true &&  event.target.defaultValue === "jardin"){
            //setSelectedLayer("jardin");
            //setRadioVal("type");
            setCheckbox11("jardin");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "jardin"){
            //setSelectedLayer("");
            setCheckbox11("");
        }
        //----End

        if(event.target.checked === true &&  event.target.defaultValue === "ecoleElectrifiee"){
            //setSelectedLayer("ecole_primaire");
            //setRadioVal("type");
            setCheckbox30("ecoleElectrifiee");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "ecoleElectrifiee"){
            //setSelectedLayer("");
            setCheckbox30("");
        }
        //----End


        if(event.target.checked === true &&  event.target.defaultValue === "ecoleNonElectrifiee"){
            //setSelectedLayer("jardin");
            //setRadioVal("type");
            setCheckbox31("ecoleNonElectrifiee");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "ecoleNonElectrifiee"){
            //setSelectedLayer("");
            setCheckbox31("");
        }
        //----End

        //*** Eclairage layers *********

        if(event.target.checked === true &&  event.target.defaultValue === "lampadairesWorking"){
            //setSelectedLayer("lampadairesWorking");
            //setRadioVal("type");
            setCheckbox12("lampadairesWorking");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "lampadairesWorking"){
            //setSelectedLayer("");
            setCheckbox12("");
        }
        //----End

        if(event.target.checked === true &&  event.target.defaultValue === "lampadairesNotWorking"){
            //setSelectedLayer("lampadairesNotWorking");
            //setRadioVal("type");
            setCheckbox13("lampadairesNotWorking");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "lampadairesNotWorking"){
            //setSelectedLayer("");
            setCheckbox13("");
        }
        //----End


        if(event.target.checked === true &&  event.target.defaultValue === "pref"){
            //setSelectedLayer("pref");
            //setRadioVal("type");
            setCheckbox0("pref");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "pref"){
            //setSelectedLayer("");
            setCheckbox0("");
        }
        //----End
    };
*/
    useEffect(()=>{

        loadData();
        // eslint-disable-next-line
    }, []);

    //const [educationLevel, setEducationLevel] = React.useState('');
/*
    const handleChangeEducationLevel = (event) => {
        setEducationLevel(event.target.value);
    };
*/
    //const [state, setState] = React.useState('');

/*
    const handleChangeCheckbox = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };
*/

    const [isHideCouvrage, setHideCouvrage] = useState("false");
    const [isHideIndicator, setHideIndicator] = useState("false");

    const handleChangeRadio = (event)=>{
        if(event.target.checked === true){
            
            let selectedValue = event.target.defaultValue;
            console.log(selectedValue)
            setRadioEau(selectedValue);
        }else{
            setRadioEau("");
        }
    };

    const handleRadioEau = () => {
        //setRadioEau("");
        setCatonButtonClicked(false);
    };

    const handleCantonButtonClicked = () => {
        setRadioEau("");
        setCatonButtonClicked(true);
        setSelectedCounty(15);
    };

    return (
        <div>
            {/*<Header/>*/}
            <div className="water-container">
                <div className="education-container-menu">
                    <SideMenu/>
                </div>
                <div className="education-container-sidebar">
                    <div className="education-container-sidebar-title">
                        <h2>
                            <img src={analyseIcon} alt="icon"/>
                            <span>Indicateurs</span>
                        </h2>
                    </div>
                    <p>
                        Basés sur les données dans le cadre du PRISE, cette section propose des analyses et indicateurs clés pour certains secteurs dans la région des Savanes.
                    </p>
                    <div className="education-container-sidebar-list">
                        <div className="education-container-sidebar-list-item">
                            <img src={eau} alt="eau"/>
                            {/*<div className="education-container-sidebar-list-item-placeholder">
                                <h4>placeholder</h4>
                            </div>*/}
                            <div className="education-container-sidebar-list-item-text">
                                <h5>Eau</h5>
                                <p>
                                    Structure du réseau d'approvisionnement en eau et accès à l'eau potable.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="education-container-sidebar-filter">
                        <div className="education-container-sidebar-filter-item">
                            <ul>
                                <li>
                                    <button
                                        className={isHideIndicator ? 'selected' : ''}
                                        onClick={()=> {
                                        handleRadioEau();
                                        setHideIndicator(true);
                                        setHideCouvrage(true);
                                    }}>Région</button>
                                </li>
                                <li>
                                    <button
                                        className={!isHideCouvrage ? 'selected' : ''}
                                        onClick={() =>{
                                            handleCantonButtonClicked();
                                            setHideIndicator(false);
                                            setHideCouvrage(false);
                                        }}>
                                        Local
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className={"education-container-sidebar-filter-item"}>
                            <div className={"education-container-sidebar-filter-item-checklist " + (!isHideCouvrage ? '' : 'hide')}>
                                <div className="education-container-sidebar-filter-item-checklist-item">
                                    <p>Couvrage</p>
                                    <ul className="canvas-wrapper">
                                        <li>
                                            <span className="polygon" style={{ backgroundColor: 'yellow' }}/>
                                        </li>
                                        <li>
                                            <span className="text">Zone de couverture – actuel</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="education-container-sidebar-filter-item-checklist-item">
                                    <p>Points d’eau</p>
                                    <ul className="canvas-wrapper">
                                        <li>
                                            <span className="circle" style={{ backgroundColor: '#AA3A3A' }}/>
                                        </li>
                                        <li>
                                            <span className="text">Poste d'eau autonome</span>
                                        </li>
                                    </ul>
                                    <ul className="canvas-wrapper">
                                        <li>
                                            <span className="circle" style={{ backgroundColor: '#512D6D' }}/>
                                        </li>
                                        <li>
                                            <span className="text">Borne fontaine</span>
                                        </li>
                                    </ul>
                                    <ul className="canvas-wrapper">
                                        <li>
                                            <span className="circle" style={{ backgroundColor: '#387C6D' }}/>
                                        </li>
                                        <li>
                                            <span className="text">Pompe a motricité humaine</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={"education-container-sidebar-filter-item-indicator " + (!isHideIndicator ? 'hide' : '')}>
                                <p>Indicateurs</p>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="couverte"
                                        name="radio-buttons-group"
                                    >
                                        <div className="radio-group-item">
                                            <FormControlLabel value="couverte" control={<Radio value="pop_couverte" onChange={handleChangeRadio} />} label="Population < 0,5km d’un point d’eau potable (est. %)" />
                                            <span className="indicator-color">
                                                <span/>
                                                <span/>
                                                <span/>
                                                <span/>
                                                <span/>
                                            </span>
                                        </div>
                                        <div className="radio-group-item">
                                            <FormControlLabel value="population" control={<Radio value="habitant_par_point" onChange={handleChangeRadio} />} label="Habitants par point d’eau (Moy)" />
                                            <span className="indicator-color">
                                                <span/>
                                                <span/>
                                                <span/>
                                                <span/>
                                                <span/>
                                            </span>
                                        </div>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cartes-wrapper-map">
                    <div className="cartes-wrapper-map-echelle">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="echelle1a-content"
                                id="echelle1a-header"
                            >
                                <Typography>Echelle</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="accordion-details-list">

                                    <div className="accordion-details-list-item">
                                        <Autocomplete
                                            id="regions"
                                            noOptionsText={'Aucune région'}
                                            options={regionsPrefectureList}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Régions" variant="outlined" />
                                            )}
                                            value={regionSelected}
                                            onChange={(event, newRegion) => {
                                                setRegionSelected(newRegion);
                                                newRegion && setRegionSelectedPrefectures(newRegion.prefectures);
                                                if(!newRegion) setSelectedCounty(0);
                                            }}
                                        />
                                    </div>
                                    {
                                        regionSelectedPrefectures && regionSelected ? (
                                            <div className="accordion-details-list-item">
                                                <Autocomplete
                                                    id="prefectures"
                                                    noOptionsText={'Aucune préfecture'}
                                                    options={regionSelectedPrefectures.map((option) => option.id+'-'+option.name)}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Préfectures" variant="outlined" />
                                                    )}

                                                    onChange={(event, newPrefecture) => {
                                                        newPrefecture && setSelectedCounty(newPrefecture.split("-")[0]);
                                                        if(!newPrefecture) setSelectedCounty(0);
                                                    }}

                                                />
                                            </div>
                                        ) : ('')
                                    }
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>


                    { !isHideIndicator ? '' :
                    <div className="cartes-wrapper-map-legend">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="legend-content"
                                id="legend-header"
                            >
                                <Typography>Légende</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="cartes-wrapper-map-legend-list">
                                    <div className="cartes-wrapper-map-legend-list-header">
                                        <h3>Population &lt; 0,5km d’un point d’eau potable (est. %)</h3>
                                    </div>
                                    <div className="cartes-wrapper-map-legend-list-content">
                                        <ul>
                                            <li className="rectangle"><span style={{backgroundColor: '#d7191c'}}/></li>
                                            <li className="name">0 - 20</li>
                                        </ul>
                                        <ul>
                                            <li className="rectangle"><span style={{backgroundColor: '#fdae61'}}/></li>
                                            <li className="name">20 - 40</li>
                                        </ul>
                                        <ul>
                                            <li className="rectangle"><span style={{backgroundColor: '#ffffbf'}}/></li>
                                            <li className="name">40 - 60</li>
                                        </ul>
                                        <ul>
                                            <li className="rectangle"><span style={{backgroundColor: '#a7daa0'}}/></li>
                                            <li className="name">60 - 80</li>
                                        </ul>
                                        <ul>
                                            <li className="rectangle"><span style={{backgroundColor: '#68a064'}}/></li>
                                            <li className="name">80 - 100</li>
                                        </ul>
                                    </div>
                                    <div className="cartes-wrapper-map-legend-list-header">
                                        <h3>Habitants par point d’eau (Moy)</h3>
                                    </div>
                                    <div className="cartes-wrapper-map-legend-list-content">
                                        <ul>
                                            <li className="rectangle"><span style={{backgroundColor: '#d7191c'}}/></li>
                                            <li className="name">377 - 1486</li>
                                        </ul>
                                        <ul>
                                            <li className="rectangle"><span style={{backgroundColor: '#fdae61'}}/></li>
                                            <li className="name">292 - 377</li>
                                        </ul>
                                        <ul>
                                            <li className="rectangle"><span style={{backgroundColor: '#ffffbf'}}/></li>
                                            <li className="name">237 - 292</li>
                                        </ul>
                                        <ul>
                                            <li className="rectangle"><span style={{backgroundColor: '#a7daa0'}}/></li>
                                            <li className="name">184 - 237</li>
                                        </ul>
                                        <ul>
                                            <li className="rectangle"><span style={{backgroundColor: '#68a064'}}/></li>
                                            <li className="name">108 - 184</li>
                                        </ul>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    }
                    
                    <Map
                        subdivisionLevel={subdivisionLevel}
                        setSubdivisionLevel={setSubdivisionLevel}
                        selectedRegion={selectedRegion}
                        setSelectedRegion={setSelectedRegion}
                        selectedPrefecture={selectedPrefecture}
                        setSelectedPrefecture={setSelectedPrefecture}
                        selectedCommune={selectedCommune}
                        setSelectedCommune={setSelectedCommune}

                        layers={layers}
                        displayedLayers={displayedLayers}
                        mapData={mapData}
                        latLng={countyPositions[selectedCounty].latLng}
                        zoom={countyPositions[selectedCounty].zoom}
                        baseLayers={baseLayers}
                        layerSelectionButtons={layerSelectionButtons}
                    />
                </div>
            </div>
            {/*<Footer/>*/}
        </div>
    )

}

export default Water;