import React, {useEffect, useState} from 'react';
import './mixedCarte.scss';
import LoadDataTask from "../../../services/cartes/LoadDataTask";
import Header from "../../page_sections/header/Header";
//import {Button} from "@mui/material";
//import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
//import {NavLink} from "react-router-dom";
import SchoolIcon from "@mui/icons-material/School";
import AddIcon from "@mui/icons-material/Add";
import LightIcon from "@mui/icons-material/Light";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import Typography from "@mui/material/Typography";
import BoltIcon from "@mui/icons-material/Bolt";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@mui/material/Autocomplete";
import {baseLayers, countyPositions, regionsPrefectureList} from "../../../assets/constants/const";
import TextField from "@mui/material/TextField";
import Map from "../carte-map/Mapt";



import { 
    dataLoadInfos, 
    EducationUniversite, 
    EducationLycee, 
    EducationCollege, 
    EducationPrimaire, 
    EducationJardin, 
    EcoleElectrifiee, 
    EcoleNonElectrifiee,  
    LigneBT, 
    LigneMT, 
    PoteauBT, 
    PoteauMT, 
    LampadairesWorking, 
    LampadairesNotWorking, 
    AddPrefectures,
    AddBTBuffer
} from "./layers";

const MixedCarte = () => {


    const [subdivisionLevel, setSubdivisionLevel] = useState("prefecture");
    const [selectedRegion, setSelectedRegion] = useState(0);
    const [selectedPrefecture, setSelectedPrefecture] = useState(0);
    const [selectedCommune, setSelectedCommune] = useState(0);
    //const [selectedLayer, setSelectedLayer] = useState(0);

    const [regionSelected, setRegionSelected] = useState(null);
    const [regionSelectedPrefectures, setRegionSelectedPrefectures] = useState(null);
    //const [prefectureSelected, setPrefectureSelected] = useState(null);


    //const [selectedPref, setSelectedPref] = useState(-1);

    const [mapData, setMapData] = useState({});
    const [selectedCounty, setSelectedCounty] = useState(0);
    const [radioVal, setRadioVal] = useState("type");
    const [checkbox0, setCheckbox0] = useState("pref");
    const [checkbox20, setCheckbox20] = useState("");
    const [checkbox30, setCheckbox30] = useState("");
    const [checkbox31, setCheckbox31] = useState("");
    const [checkbox1, setCheckbox1] = useState("");
    const [checkbox2, setCheckbox2] = useState("");
    const [checkbox3, setCheckbox3] = useState("");
    const [checkbox4, setCheckbox4] = useState("");
    const [checkbox5, setCheckbox5] = useState("");
    const [checkbox6, setCheckbox6] = useState("");
    const [checkbox7, setCheckbox7] = useState("");
    const [checkbox8, setCheckbox8] = useState("");
    const [checkbox9, setCheckbox9] = useState("");
    const [checkbox10, setCheckbox10] = useState("");
    const [checkbox11, setCheckbox11] = useState("");
    const [checkbox12, setCheckbox12] = useState("");
    const [checkbox13, setCheckbox13] = useState("");
    


    const layers = [

        EducationUniversite, 
        EducationLycee, 
        EducationCollege, 
        EducationPrimaire, 
        EducationJardin, 
        EcoleElectrifiee, 
        EcoleNonElectrifiee, 
        LampadairesWorking,
        LampadairesNotWorking,
        LigneBT, 
        LigneMT,
        PoteauBT, 
        PoteauMT,
        AddPrefectures,
        AddBTBuffer
    ];

    const layerSelectionButtons = {
        radioVal,
        setRadioVal,
        checkbox1,
        setCheckbox1,
        checkbox2,
        setCheckbox2,
        checkbox3,
        setCheckbox3,
        checkbox4,
        setCheckbox4,
        checkbox5,
        setCheckbox5,
        checkbox6,
        setCheckbox6,
        checkbox7,
        setCheckbox7,
        checkbox8,
        setCheckbox8,
        checkbox9,
        setCheckbox9,
        checkbox10,
        setCheckbox10,
        checkbox11,
        setCheckbox11,
        checkbox12,
        setCheckbox12,
        checkbox13,
        setCheckbox13,
        checkbox0,
        setCheckbox0,
        checkbox20,
        setCheckbox20,
        checkbox30,
        setCheckbox30,
        checkbox31,
        setCheckbox31
    };

    const displayedLayers = {
        [AddPrefectures.slug]: checkbox0,
        [AddBTBuffer.slug]: checkbox20,
        [PoteauBT.slug]: checkbox1,
        [PoteauMT.slug]: checkbox2,
        //[PoteauHT.slug]: checkbox3,
        [LigneBT.slug]: checkbox4,
        [LigneMT.slug]: checkbox5,
        //[LigneHT.slug]: checkbox6,
        [AddBTBuffer.slug]: checkbox20,
        [EducationUniversite.slug]: checkbox7,
        [EducationLycee.slug]: checkbox8,
        [EducationCollege.slug]: checkbox9,
        [EducationPrimaire.slug]: checkbox10,
        [EducationJardin.slug]: checkbox11,
        [LampadairesWorking.slug]: checkbox12,
        [LampadairesNotWorking.slug]: checkbox13,
        [EcoleElectrifiee.slug]: checkbox30,
        [EcoleNonElectrifiee.slug]: checkbox31,
        
    };

    
    // eslint-disable-next-line
    const [isHide, setHide] = useState("false");
    /*const handleToggleSidebar = () => {
        setHide(!isHide);
    };*/

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    /* BELOW, the functions don't change for any sector */

    const loadData = function () {

        let mounted = { val: true };
        const loadDataTask = new LoadDataTask();
        dataLoadInfos.forEach((info) => {
            loadDataTask.load(info, mounted, setMapData);

        });
        /*sharedDataLoadInfos.forEach((info) => {
          loadDataTask.load(info, mounted, setMapData);
        });*/
        console.log(mapData);
        return () => (mounted.val = false);
    };

    /*const checkDataAvailable = () => {
        let dataAvailableFlag = true;
        dataLoadInfos.forEach((info) => {
            if (!mapData[info.fieldName]) dataAvailableFlag = false;
        });

        return dataAvailableFlag;
    };*/

    const addLayerToMap = (event) => {
        //*** Poteaux layers *********
        if(event.target.checked === true &&  event.target.defaultValue === "poteauBT"){
            //setSelectedLayer("poteauBT");
            setCheckbox1("poteauBT");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "poteauBT"){
            //setSelectedLayer("");
            setCheckbox1("");
        }
        //----End


        if(event.target.checked === true &&  event.target.defaultValue === "poteauMT"){
            //setSelectedLayer("poteauMT");
            //setRadioVal("type");
            setCheckbox2("poteauMT");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "poteauMT"){
            //setSelectedLayer("");
            setCheckbox2("");
        }
        //----End


        if(event.target.checked === true &&  event.target.defaultValue === "poteauHT"){
            //setSelectedLayer("poteauHT");
            //setRadioVal("type");
            setCheckbox3("poteauHT");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "poteauHT"){
            //setSelectedLayer("");
            setCheckbox3("");
        }
        //----End


        //*** Lignes layers *********
        if(event.target.checked === true &&  event.target.defaultValue === "ligneBT"){
            //setSelectedLayer("ligneBT");
            //setRadioVal("type");
            setCheckbox4("ligneBT");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "ligneBT"){
            //setSelectedLayer("");
            setCheckbox4("");
        }
        //----End

        if(event.target.checked === true &&  event.target.defaultValue === "ligneMT"){
            //setSelectedLayer("ligneMT");
            //setRadioVal("type");
            setCheckbox5("ligneMT");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "ligneMT"){
            //setSelectedLayer("");
            setCheckbox5("");
        }
        //----End

        if(event.target.checked === true &&  event.target.defaultValue === "ligneHT"){
            //setSelectedLayer("ligneHT");
            //setRadioVal("type");
            setCheckbox6("ligneHT");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "ligneHT"){
            //setSelectedLayer("");
            setCheckbox6("");
        }
        //----End

        if(event.target.checked === true &&  event.target.defaultValue === "ligneBTBuffer"){
            //setSelectedLayer("ligneHT");
            //setRadioVal("type");
            setCheckbox20("ligneBTBuffer");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "ligneBTBuffer"){
            //setSelectedLayer("");
            setCheckbox20("");
        }
        //----End


        //*** Education layers *********
        if(event.target.checked === true &&  event.target.defaultValue === "universite"){
            //setSelectedLayer("universite");
            //setRadioVal("type");
            setCheckbox7("universite");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "universite"){
            //setSelectedLayer("");
            setCheckbox7("");
        }
        //----End

        if(event.target.checked === true &&  event.target.defaultValue === "lycee"){
            //setSelectedLayer("lycee");
            //setRadioVal("type");
            setCheckbox8("lycee");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "lycee"){
            //setSelectedLayer("");
            setCheckbox8("");
        }
        //----End

        if(event.target.checked === true &&  event.target.defaultValue === "college"){
            //setSelectedLayer("college");
            //setRadioVal("type");
            setCheckbox9("college");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "college"){
            //setSelectedLayer("");
            setCheckbox9("");
        }
        //----End

        if(event.target.checked === true &&  event.target.defaultValue === "ecole_primaire"){
            //setSelectedLayer("ecole_primaire");
            //setRadioVal("type");
            setCheckbox10("ecole_primaire");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "ecole_primaire"){
            //setSelectedLayer("");
            setCheckbox10("");
        }
        //----End


        if(event.target.checked === true &&  event.target.defaultValue === "jardin"){
            //setSelectedLayer("jardin");
            //setRadioVal("type");
            setCheckbox11("jardin");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "jardin"){
            //setSelectedLayer("");
            setCheckbox11("");
        }
        //----End

        if(event.target.checked === true &&  event.target.defaultValue === "ecoleElectrifiee"){
            //setSelectedLayer("ecole_primaire");
            //setRadioVal("type");
            setCheckbox30("ecoleElectrifiee");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "ecoleElectrifiee"){
            //setSelectedLayer("");
            setCheckbox30("");
        }
        //----End


        if(event.target.checked === true &&  event.target.defaultValue === "ecoleNonElectrifiee"){
            //setSelectedLayer("jardin");
            //setRadioVal("type");
            setCheckbox31("ecoleNonElectrifiee");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "ecoleNonElectrifiee"){
            //setSelectedLayer("");
            setCheckbox31("");
        }
        //----End

        //*** Eclairage layers *********

        if(event.target.checked === true &&  event.target.defaultValue === "lampadairesWorking"){
            //setSelectedLayer("lampadairesWorking");
            //setRadioVal("type");
            setCheckbox12("lampadairesWorking");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "lampadairesWorking"){
            //setSelectedLayer("");
            setCheckbox12("");
        }
        //----End

        if(event.target.checked === true &&  event.target.defaultValue === "lampadairesNotWorking"){
            //setSelectedLayer("lampadairesNotWorking");
            //setRadioVal("type");
            setCheckbox13("lampadairesNotWorking");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "lampadairesNotWorking"){
            //setSelectedLayer("");
            setCheckbox13("");
        }
        //----End


        if(event.target.checked === true &&  event.target.defaultValue === "pref"){
            //setSelectedLayer("pref");
            //setRadioVal("type");
            setCheckbox0("pref");
        }
        if(event.target.checked === false &&  event.target.defaultValue === "pref"){
            //setSelectedLayer("");
            setCheckbox0("");
        }
        //----End
    };

    useEffect(()=>{
        
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <Header/>
            <div className="cartes-wrapper">
                <div className={"cartes-wrapper-sidebar " + (isHide ? '' : 'hide')}>
                    <div className="cartes-wrapper-sidebar-inner">
                        {/*<div className="cartes-wrapper-sidebar-inner-closer">
                            <Button onClick={handleToggleSidebar}>
                                <ArrowForwardIosIcon />
                            </Button>
                        </div>*/}
                        <h2 className="cartes-wrapper-sidebar-inner-title">Couches (3)</h2>
                        <div className="cartes-wrapper-sidebar-inner-menu">
                            <ul>
                                <li>
                                    <div className="accordion-wrapper">
                                        {/* Education et formation accordion */}
                                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                            <AccordionSummary
                                                expandIcon={expanded === 'panel1' ? <RemoveIcon /> : <AddIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography><SchoolIcon />Education et formation</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="accordion-details-title">
                                                    Catégorie établissement
                                                </div>
                                                <div className="accordion-details-content">
                                                    
                                                    {/*<FormControlLabel control={<Checkbox />} label="Université"
                                                                      value="universite"
                                                                      onChange={addLayerToMap}
                                                    />
                                                    <FormControlLabel control={<Checkbox />} label="Lycée"
                                                                      value="lycee"
                                                                      onChange={addLayerToMap}
                                                    />
                                                    <FormControlLabel control={<Checkbox />} label="Collège"
                                                                      value="college"
                                                                      onChange={addLayerToMap}
                                                    />
                                                    <FormControlLabel control={<Checkbox />} label="Ecole primaire"
                                                                      value="ecole_primaire"
                                                                      onChange={addLayerToMap}
                                                    />
                                                    <FormControlLabel control={<Checkbox />} label="Jardin (maternelle)"
                                                                      value="jardin"
                                                                      onChange={addLayerToMap}
                                                    />
                                                    <FormControlLabel control={<Checkbox />} label="Collège"
                                                                      value="college"
                                                                      onChange={addLayerToMap}
                                                    />*/}
                                                    <FormControlLabel control={<Checkbox />} label="Ecoles Electrifiées"
                                                                      value="ecoleElectrifiee"
                                                                      onChange={addLayerToMap}
                                                    />
                                                    <FormControlLabel control={<Checkbox />} label="Ecoles Non Electrifiées"
                                                                      value="ecoleNonElectrifiee"
                                                                      onChange={addLayerToMap}
                                                    />
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                        {/* Eclairage public */}
                                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                            <AccordionSummary
                                                expandIcon={expanded === 'panel2' ? <RemoveIcon /> : <AddIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header"
                                            >
                                                <Typography><LightIcon />Eclairage public</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="accordion-details-title">
                                                    Réseau d'éclairage public
                                                </div>
                                                <div className="accordion-details-content">
                                                    <FormControlLabel control={<Checkbox />} label="Lampadaires Fonctionnels"
                                                                      value="lampadairesWorking"
                                                                      onChange={addLayerToMap}
                                                    />
                                                    <FormControlLabel control={<Checkbox />} label="Lampadaires Non Fonctionnels"
                                                                      value="lampadairesNotWorking"
                                                                      onChange={addLayerToMap}
                                                    />
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                        {/* Electricite */}
                                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                            <AccordionSummary
                                                expandIcon={expanded === 'panel3' ? <RemoveIcon /> : <AddIcon />}
                                                aria-controls="panel3a-content"
                                                id="panel3a-header"
                                            >
                                                <Typography><BoltIcon />Electricité</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="accordion-details-title">
                                                    Réseau Électricité - Poteaux
                                                </div>
                                                <div className="accordion-details-content">
                                                    <FormControlLabel
                                                        control={<Checkbox />}
                                                        label="Poteaux BT"
                                                        value="poteauBT"
                                                        onChange={addLayerToMap}
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox />}
                                                        label="Poteaux MT"
                                                        value="poteauMT"
                                                        onChange={addLayerToMap}
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox />}
                                                        label="Poteaux HT"
                                                        value="poteauHT"
                                                        onChange={addLayerToMap}
                                                    />
                                                </div>
                                                <div className="accordion-details-title">
                                                    Réseau Électricité - Lignes
                                                </div>
                                                <div className="accordion-details-content">
                                                    <FormControlLabel
                                                        control={<Checkbox />}
                                                        label="Ligne BT"
                                                        value="ligneBT"
                                                        onChange={addLayerToMap}
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox />}
                                                        label="Ligne MT"
                                                        value="ligneMT"
                                                        onChange={addLayerToMap}
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox />}
                                                        label="Ligne HT"
                                                        value="ligneHT"
                                                        onChange={addLayerToMap}
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox />}
                                                        label="Ligne BT BUFFER"
                                                        value="ligneBTBuffer"
                                                        onChange={addLayerToMap}
                                                    />
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={"cartes-wrapper-map " + (isHide ? '' : 'full')}>
                    <div className="cartes-wrapper-map-echelle">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="echelle1a-content"
                                id="echelle1a-header"
                            >
                                <Typography>Echelle</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="accordion-details-list">

                                    <div className="accordion-details-list-item">
                                        <Autocomplete
                                            id="regions"
                                            noOptionsText={'Aucune région'}
                                            options={regionsPrefectureList}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Régions" variant="outlined" />
                                            )}
                                            value={regionSelected}
                                            onChange={(event, newRegion) => {
                                                setRegionSelected(newRegion);
                                                newRegion && setRegionSelectedPrefectures(newRegion.prefectures);
                                                if(!newRegion) setSelectedCounty(0);
                                            }}
                                        />
                                    </div>
                                    {
                                        regionSelectedPrefectures && regionSelected ? (
                                            <div className="accordion-details-list-item">
                                                <Autocomplete
                                                    id="prefectures"
                                                    noOptionsText={'Aucune préfecture'}
                                                    options={regionSelectedPrefectures.map((option) => option.id+'-'+option.name)}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Préfectures" variant="outlined" />
                                                    )}

                                                    onChange={(event, newPrefecture) => {
                                                        newPrefecture && setSelectedCounty(newPrefecture.split("-")[0]);
                                                        if(!newPrefecture) setSelectedCounty(0);
                                                    }}

                                                />
                                            </div>
                                        ) : ('')
                                    }
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="cartes-wrapper-map-legend">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="legend-content"
                                id="legend-header"
                            >
                                <Typography>Légende</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="cartes-wrapper-map-legend-list">
                                    {/* Education et formation legende */}
                                    <div className="cartes-wrapper-map-legend-list-header">
                                        <h3>Education et formation</h3>
                                    </div>
                                    <div className="cartes-wrapper-map-legend-list-content">
                                        <ul>
                                            <li className="circle"><img src="/images/etablissement/lycee.svg" alt="icon"/></li>
                                            <li className="name">Ecoles Electrifiées</li>
                                        </ul>
                                        <ul>
                                            <li className="circle"><img src="/images/etablissement/university.svg" alt="icon"/></li>
                                            <li className="name">Ecoles Non Electrifiées</li>
                                        </ul>
                                        {/*
                                        <ul>
                                            <li className="circle"><img src="/images/etablissement/college.svg" alt="icon"/></li>
                                            <li className="name">Collège</li>
                                        </ul>
                                        <ul>
                                            <li className="circle"><img src="/images/etablissement/primaire.svg" alt="icon"/></li>
                                            <li className="name">Primaire</li>
                                        </ul>
                                        <ul>
                                            <li className="circle"><img src="/images/etablissement/maternelle.svg" alt="icon"/></li>
                                            <li className="name">Maternelle</li>
                                        </ul>
                                    */}
                                    </div>
                                    {/* Eclairage public legende */}
                                    <div className="cartes-wrapper-map-legend-list-header">
                                        <h3>Eclairage public</h3>
                                    </div>
                                    <div className="cartes-wrapper-map-legend-list-content">
                                        <ul>
                                            <li className="circle"><span style={{borderColor: 'purple'}}/></li>
                                            <li className="name">Lampadaires Fonctionnels</li>
                                        </ul>
                                        <ul>
                                            <li className="circle"><span style={{borderColor: 'black'}}/></li>
                                            <li className="name">Lampadaires Non Fonctionnels</li>
                                        </ul>
                                    </div>
                                    {/* Electricité legende */}
                                    <div className="cartes-wrapper-map-legend-list-header">
                                        <h3>Electricité</h3>
                                    </div>
                                    <div className="cartes-wrapper-map-legend-list-content">
                                        <ul>
                                            <li className="circle"><span style={{borderColor: 'blue'}}/></li>
                                            <li className="name">Poteaux BT</li>
                                        </ul>
                                        <ul>
                                            <li className="circle"><span style={{borderColor: 'red'}}/></li>
                                            <li className="name">Poteaux MT</li>
                                        </ul>
                                        <ul>
                                            <li className="circle"><span style={{borderColor: '#06FF00'}}/></li>
                                            <li className="name">Poteaux HT</li>
                                        </ul>
                                        <ul>
                                            <li className="line"><span style={{backgroundColor: '#A7D129'}}/></li>
                                            <li className="name">Ligne BT</li>
                                        </ul>
                                        <ul>
                                            <li className="line"><span style={{backgroundColor: 'orange'}}/></li>
                                            <li className="name">Ligne MT</li>
                                        </ul>
                                        <ul>
                                            <li className="line"><span style={{backgroundColor: '#89aa97'}}/></li>
                                            <li className="name">Ligne HT</li>
                                        </ul>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    <Map
                        subdivisionLevel={subdivisionLevel}
                        setSubdivisionLevel={setSubdivisionLevel}
                        selectedRegion={selectedRegion}
                        setSelectedRegion={setSelectedRegion}
                        selectedPrefecture={selectedPrefecture}
                        setSelectedPrefecture={setSelectedPrefecture}
                        selectedCommune={selectedCommune}
                        setSelectedCommune={setSelectedCommune}

                        layers={layers}
                        displayedLayers={displayedLayers}
                        mapData={mapData}
                        latLng={countyPositions[selectedCounty].latLng}
                        zoom={countyPositions[selectedCounty].zoom}
                        baseLayers={baseLayers}
                        layerSelectionButtons={layerSelectionButtons}
                    />
                </div>
            </div>
            {/*<Footer/>*/}
        </div>
    )

}

export default MixedCarte;