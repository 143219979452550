import axios from 'axios';
import { apiUrl } from '../utils/axios';


export const regionsList = async () =>{
	const {data} = await axios.get(`${apiUrl}regions`);
	return data;
};


export const prefecturesList = async () =>{
	const {data} = await axios.get(`${apiUrl}prefectures`);
	return data;
};


export const communesList = async () =>{
	const {data} = await axios.get(`${apiUrl}communes`);
	return data;
};





/*
export function getAllCategories(){
    return axios.get(`${apiUrl}/get_categories_with_sub`);
}

*/
