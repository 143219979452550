import L from "leaflet";

/* DATA TO IMPORT */

export const dataLoadInfos = [
    {
        dataUrl: "/local_db/education/Etablissements_savanes.geojson",
        fieldName: "educationInfo",
    },
    {
        dataUrl: "/local_db/eclairage/Lampadaires_savanes.geojson",
        fieldName: "lampadaireInfo",
    },
    {
        dataUrl: "/local_db/electricite/BTPoteaux_savanes.geojson",
        fieldName: "poteauBTInfo",
    },
    {
        dataUrl: "/local_db/electricite/MTPoteaux_savanes.geojson",
        fieldName: "poteauMTInfo",
    },
    {
        dataUrl: "/local_db/electricite/HTPoteaux_savanes.geojson",
        fieldName: "poteauHTInfo",
    },
    {
        dataUrl: "/local_db/electricite/BTLignes_savanes.geojson",
        fieldName: "ligneBTInfo",
    },
    {
        dataUrl: "/local_db/electricite/MTLignes_savanes.geojson",
        fieldName: "ligneMTInfo",
    },
    {
        dataUrl: "/local_db/electricite/HTLignes_savanes.geojson",
        fieldName: "ligneHTInfo",
    },
    {
        dataUrl: "/geodata/prefecture_savanes.geojson",
        fieldName: "prefectureInfo",
    },
    {
        dataUrl: "/geodata/reprojcantons_togo_v2.geojson",
        fieldName: "cantonInfo",
    },
    {
        dataUrl: "/local_db/electricite/lignes_bt_buffer_savanes.geojson",
        fieldName: "bufferInfo",
    },
    {
        dataUrl: "/local_db/new/Energie/Couverture_exist.geojson",
        fieldName: "couvertureExistInfo",
    },
    {
        dataUrl: "/local_db/new/Energie/Couverture_futur.geojson",
        fieldName: "couvertureFuturInfo",
    },
    {
        dataUrl: "/local_db/new/Energie/lampadaires_etat.geojson",
        fieldName: "lampadairesEtatInfo",
    },
    {
        dataUrl: "/local_db/new/Energie/ligne_bt_mt.geojson",
        fieldName: "ligneBtMtInfo",
    },
    {
        dataUrl: "/local_db/new/Energie/reseau_actuel_futur.geojson",
        fieldName: "reseauActuelFuturInfo",
    },

];

/*export const dataLoadInfos = [
  {
    dataUrl:
      "/local_db/education/20210129-etablissement_education.geojson",
    fieldName: "educationInfo",
  },
];*/

let ligneBTColor = {
    //color: "#f2d338",
    color: "#A7D129",
    weight: 3,
    opacity: 1,
    forceZIndex: 120,
};

let ligneMTColor = {
    //color: "#db921d",
    color: "orange",
    weight: 5,
    opacity: 1,
    forceZIndex: 2000,
};

/*
let ligneHTColor = {
    color: "#89aa97",
    weight: 8,
    opacity: 1,
};
*/

let poteauBTColor = {
    color: "blue",
    weight: 1,
    opacity: 1,
    radius: 2
};

let poteauMTColor = {
    color: "red",
    weight: 1,
    opacity: 1,
    radius: 5
};

/*
let poteauHTColor = {
    color: "#06FF00",
    weight: 1,
    opacity: 1,
    radius: 2
};
*/

/* ICONS */

/*

const poteauBTIcon = new L.Icon({
    iconUrl: "/images/electricite/Pbasse-tension.svg",
    shadowUrl: "",
    iconSize: [20, 20], // size of the icon
    shadowSize: [16, 16], // size of the shadow
    iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0], // the same for the shadow
    popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});

const poteauMTIcon = new L.Icon({
    iconUrl: "/images/electricite/Pmoyenne-tension.svg",
    shadowUrl: "",
    iconSize: [20, 20], // size of the icon
    shadowSize: [16, 16], // size of the shadow
    iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0], // the same for the shadow
    popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});

const poteauHTIcon = new L.Icon({
    iconUrl: "/images/electricite/Phaute-tension.svg",
    shadowUrl: "",
    iconSize: [20, 20], // size of the icon
    shadowSize: [16, 16], // size of the shadow
    iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0], // the same for the shadow
    popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});
*/

const universiteIcon = new L.Icon({
    iconUrl: "/images/etablissement/university.svg",
    shadowUrl: "",
    iconSize: [30, 30], // size of the icon
    shadowSize: [16, 16], // size of the shadow
    iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0], // the same for the shadow
    popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});

const lyceeIcon = new L.Icon({
    iconUrl: "/images/etablissement/lycee.svg",
    shadowUrl: "",
    iconSize: [30, 30], // size of the icon
    shadowSize: [16, 16], // size of the shadow
    iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0], // the same for the shadow
    popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});

const collegeIcon = new L.Icon({
    iconUrl: "/images/etablissement/college.svg",
    shadowUrl: "",
    iconSize: [30, 30], // size of the icon
    shadowSize: [16, 16], // size of the shadow
    iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0], // the same for the shadow
    popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});


const primaireIcon = new L.Icon({
    iconUrl: "/images/etablissement/primaire.svg",
    shadowUrl: "",
    iconSize: [30, 30], // size of the icon
    shadowSize: [16, 16], // size of the shadow
    iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0], // the same for the shadow
    popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});


const jardinIcon = new L.Icon({
    iconUrl: "/images/etablissement/maternelle.svg",
    shadowUrl: "",
    iconSize: [30, 30], // size of the icon
    shadowSize: [16, 16], // size of the shadow
    iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0], // the same for the shadow
    popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});

/*
const lampIcon = new L.Icon({
    iconUrl: "/images/eclairage/street-lamp-icon.svg",
    shadowUrl: "",
    iconSize: [14, 14], // size of the icon
    shadowSize: [16, 16], // size of the shadow
    iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0], // the same for the shadow
    popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});

const lampIcon2 = new L.Icon({
    iconUrl: "/images/eclairage/street-lamp-icon-2.svg",
    shadowUrl: "",
    iconSize: [14, 14], // size of the icon
    shadowSize: [16, 16], // size of the shadow
    iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0], // the same for the shadow
    popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
});
*/

let lampadaireWorkingColor = {
    color: "purple",
    weight: 1,
    opacity: 1,
    radius: 2
};

let lampadaireNotWorkingColor = {
    color: "black",
    weight: 1,
    opacity: 1,
    radius: 5
};


/* LAYERS TO CREATE */



export const PoteauBT = {
    slug: "poteauBT",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change
        const data = dataSource.poteauBTInfo;

        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {

                const { checkbox1 } = layerSelectionButtons;


                //TBM?
                // instead of using a filter, just display non-selected items in grey
                if (checkbox1 === "poteauBT") {
                    //if (feature.properties["Type d'établissement 1"] === "Pré Scolaire") {

                    return  L.circleMarker(latlng, poteauBTColor);
                    //return L.marker(latlng, {icon: poteauBTIcon});
                }


            },
            onEachFeature: function (feature, layer) {
                layer
                    .bindPopup(
                        "Matériau :  " +
                        feature.properties["poteau_materiau"] +
                        "<br>" +
                        "Qualité poteau: " +
                        feature.properties["poteau_qualite"] +
                        "<br>" +
                        "Mise à terre? :  " +
                        feature.properties["mise_terre"] +
                        "<br>"

                    )
                    .addTo(map);
            },
            style: function (feature) {
                return poteauBTColor;
            }
        });
        myLayer.addTo(map);
        return myLayer;
    },
};


export const PoteauMT = {
    slug: "poteauMT",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change
        const data = dataSource.poteauMTInfo;

        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {

                const { checkbox2 } = layerSelectionButtons;


                //TBM?
                // instead of using a filter, just display non-selected items in grey
                if (checkbox2 === "poteauMT") {
                    //if (feature.properties["Type d'établissement 1"] === "Pré Scolaire") {

                    return  L.circleMarker(latlng, poteauMTColor);
                    //return L.marker(latlng, {icon: poteauMTIcon});
                }


            },
            onEachFeature: function (feature, layer) {
                layer
                    .bindPopup(
                        "Matériau :  " +
                        feature.properties["poteau_materiau"] +
                        "<br>" +
                        "Qualité poteau:  " +
                        feature.properties["poteau_qualite"] +
                        "<br>" +
                        "Mise à terre? :  " +
                        feature.properties["mise_terre"] +
                        "<br>"

                    )
                    .addTo(map);
            },
            style: function (feature) {
                return poteauMTColor;
            }
        });
        myLayer.addTo(map);
        return myLayer;
    },
};


export const LigneBT = {
    slug: "ligneBT",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change
        //console.log("Ligne Base tensions");
        const data = dataSource.ligneBTInfo;
        //console.log(data);
        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {

                const { checkbox4 } = layerSelectionButtons;


                //TBM?
                // instead of using a filter, just display non-selected items in grey
                if (checkbox4 === "ligneMT") {
                    //if (feature.properties["Type d'établissement 1"] === "Pré Scolaire") {

                    //return L.marker(latlng, {icon: poteauMTIcon});
                }


            },
            onEachFeature: function (feature, layer) {

                layer
                    .bindPopup(
                        "Ligne Souterraine? :  " +
                        feature.properties["ligne_sousterr"] +
                        "<br>" +
                        "Public ou Privé? :  " +
                        feature.properties["ligne_propriete"] +
                        "<br>" +
                        "Nouvelle Ligne? :  " +
                        feature.properties["ligne_nouv"] +
                        "<br>" +
                        "Préfecture :  " +
                        feature.properties["prefecture_projet"] +
                        "<br>"

                    )
                    .addTo(map);
            },
            style: function (feature) {
                return ligneBTColor;
            }
        });
        myLayer.addTo(map);
        return myLayer;
    },
};


export const LigneMT = {
    slug: "ligneMT",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change
        //console.log("poteau M T");
        const data = dataSource.ligneMTInfo;

        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {

                const { checkbox5 } = layerSelectionButtons;


                //TBM?
                // instead of using a filter, just display non-selected items in grey
                if (checkbox5 === "ligneMT") {
                    //if (feature.properties["Type d'établissement 1"] === "Pré Scolaire") {

                    //return L.marker(latlng, {icon: poteauMTIcon});
                }


            },
            onEachFeature: function (feature, layer) {
                layer
                    .bindPopup(

                        "Ligne Souterraine? :  " +
                        feature.properties["ligne_sousterr"] +
                        "<br>" +
                        "Public ou Privé? :  " +
                        feature.properties["ligne_propriete"] +
                        "<br>" +
                        "Nouvelle Ligne? :  " +
                        feature.properties["ligne_nouv"] +
                        "<br>" +
                        "Préfecture :  " +
                        feature.properties["prefecture_projet"] +
                        "<br>"

                    )
                    .addTo(map);
            },
            style: function (feature) {
                return ligneMTColor;
            }
        });
        myLayer.addTo(map);
        return myLayer;
    },
};

//**************** Ecole Electrifiee ou non  *******************///
export const EcoleElectrifiee = {
    slug: "ecoleElectrifiee",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change
        const data = dataSource.educationInfo;
        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {
                //console.log(feature);
                const { checkbox30 } = layerSelectionButtons;


                //TBM?
                // instead of using a filter, just display non-selected items in grey
                if (checkbox30 === "ecoleElectrifiee" && feature.properties["elec_acces"] && feature.properties["elec_acces"] === "Oui") {
                    //if (feature.properties["Type d'établissement 1"] === "Pré Scolaire") {


                    //console.log(feature);
                    return L.marker(latlng, {icon: lyceeIcon});
                }

            },
            onEachFeature: function (feature, layer) {
                layer
                    .bindPopup(
                        "Nom de l'établissement :  " +
                        feature.properties["etablissement_nom"] +
                        "<br>" +
                        "Type d'établissement :  " +
                        feature.properties["etablissement_categorie"] +
                        "<br>" +
                        "Public ou Privé? :  " +
                        feature.properties["etablissement_secteur"] +
                        "<br>" +
                        "Canton :  " +
                        feature.properties["canton_nom"] +
                        "<br>" +
                        "Effectif :  " +
                        feature.properties["eleve_nbr"] +
                        "<br>"
                        /*"<br>" +
                        "Élèves / Classe -  " +
                        (feature.properties["Eleves / Classe"] &&
                        parseInt(feature.properties["Eleves / Classe"], 10) > 0
                          ? parseInt(feature.properties["Eleves / Classe"], 10)
                          : "inconnu") +
                        (layerSelectionButtons.loggedIn
                          ? "<br>" +
                            "Contact Direction -  " +
                            feature.properties["Autorités/Contacts 1"]
                          : "")
                          */
                    )
                    .addTo(map);
            },
        });
        myLayer.addTo(map);
        return myLayer;
    },
};


export const EcoleNonElectrifiee = {
    slug: "ecoleNonElectrifiee",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change
        const data = dataSource.educationInfo;
        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {
                //console.log(feature);
                const { checkbox31 } = layerSelectionButtons;


                //TBM?
                // instead of using a filter, just display non-selected items in grey
                if (checkbox31 === "ecoleNonElectrifiee" && feature.properties["elec_acces"] && feature.properties["elec_acces"] === "Non") {

                    //console.log(feature);
                    return L.marker(latlng, {icon: universiteIcon});
                }

            },
            onEachFeature: function (feature, layer) {
                layer
                    .bindPopup(
                        "Nom de l'établissement :  " +
                        feature.properties["etablissement_nom"] +
                        "<br>" +
                        "Type d'établissement :  " +
                        feature.properties["etablissement_categorie"] +
                        "<br>" +
                        "Public ou Privé? :  " +
                        feature.properties["etablissement_secteur"] +
                        "<br>" +
                        "Canton :  " +
                        feature.properties["canton_nom"] +
                        "<br>" +
                        "Effectif :  " +
                        feature.properties["eleve_nbr"] +
                        "<br>"
                        /*"<br>" +
                        "Élèves / Classe -  " +
                        (feature.properties["Eleves / Classe"] &&
                        parseInt(feature.properties["Eleves / Classe"], 10) > 0
                          ? parseInt(feature.properties["Eleves / Classe"], 10)
                          : "inconnu") +
                        (layerSelectionButtons.loggedIn
                          ? "<br>" +
                            "Contact Direction -  " +
                            feature.properties["Autorités/Contacts 1"]
                          : "")
                          */
                    )
                    .addTo(map);
            },
        });
        myLayer.addTo(map);
        return myLayer;
    },
};

//********* END

export const EducationUniversite = {
    slug: "educationUniversite",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change
        const data = dataSource.educationInfo;
        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {
                //console.log(feature);
                const { checkbox7 } = layerSelectionButtons;


                //TBM?
                // instead of using a filter, just display non-selected items in grey
                if (checkbox7 === "universite" && feature.properties["etablissement_categorie"] && feature.properties["etablissement_categorie"].toLowerCase().includes("universite")) {
                    //if (feature.properties["Type d'établissement 1"] === "Pré Scolaire") {


                    //console.log(feature);
                    return L.marker(latlng, {icon: lyceeIcon});
                }

            },
            onEachFeature: function (feature, layer) {
                layer
                    .bindPopup(
                        "Nom de l'établissement :  " +
                        feature.properties["etablissement_nom"] +
                        "<br>" +
                        "Type d'établissement :  " +
                        feature.properties["etablissement_categorie"] +
                        "<br>" +
                        "Public ou Privé? :  " +
                        feature.properties["etablissement_secteur"] +
                        "<br>" +
                        "Canton :  " +
                        feature.properties["canton_nom"] +
                        "<br>" +
                        "Effectif :  " +
                        feature.properties["eleve_nbr"] +
                        "<br>"
                        /*"<br>" +
                        "Élèves / Classe -  " +
                        (feature.properties["Eleves / Classe"] &&
                        parseInt(feature.properties["Eleves / Classe"], 10) > 0
                          ? parseInt(feature.properties["Eleves / Classe"], 10)
                          : "inconnu") +
                        (layerSelectionButtons.loggedIn
                          ? "<br>" +
                            "Contact Direction -  " +
                            feature.properties["Autorités/Contacts 1"]
                          : "")
                          */
                    )
                    .addTo(map);
            },
        });
        myLayer.addTo(map);
        return myLayer;
    },
};


export const EducationLycee = {
    slug: "educationLycee",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change
        const data = dataSource.educationInfo;
        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {
                //console.log(feature);
                const { checkbox8 } = layerSelectionButtons;


                //TBM?
                // instead of using a filter, just display non-selected items in grey
                if (checkbox8 === "lycee" && feature.properties["etablissement_categorie"] && feature.properties["etablissement_categorie"].toLowerCase().includes("lycée")) {

                    //console.log(feature);
                    return L.marker(latlng, {icon: universiteIcon});
                }

            },
            onEachFeature: function (feature, layer) {
                layer
                    .bindPopup(
                        "Nom de l'établissement :  " +
                        feature.properties["etablissement_nom"] +
                        "<br>" +
                        "Type d'établissement :  " +
                        feature.properties["etablissement_categorie"] +
                        "<br>" +
                        "Public ou Privé? :  " +
                        feature.properties["etablissement_secteur"] +
                        "<br>" +
                        "Canton :  " +
                        feature.properties["canton_nom"] +
                        "<br>" +
                        "Effectif :  " +
                        feature.properties["eleve_nbr"] +
                        "<br>"
                        /*"<br>" +
                        "Élèves / Classe -  " +
                        (feature.properties["Eleves / Classe"] &&
                        parseInt(feature.properties["Eleves / Classe"], 10) > 0
                          ? parseInt(feature.properties["Eleves / Classe"], 10)
                          : "inconnu") +
                        (layerSelectionButtons.loggedIn
                          ? "<br>" +
                            "Contact Direction -  " +
                            feature.properties["Autorités/Contacts 1"]
                          : "")
                          */
                    )
                    .addTo(map);
            },
        });
        myLayer.addTo(map);
        return myLayer;
    },
};


export const EducationCollege = {
    slug: "educationCollege",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change
        const data = dataSource.educationInfo;
        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {
                //console.log(feature);
                const { checkbox9 } = layerSelectionButtons;


                //TBM?
                // instead of using a filter, just display non-selected items in grey
                if (checkbox9 === "college" && feature.properties["etablissement_categorie"] && feature.properties["etablissement_categorie"].toLowerCase().includes("college")) {

                    return L.marker(latlng, {icon: collegeIcon});
                }

            },
            onEachFeature: function (feature, layer) {
                layer
                    .bindPopup(
                        "Nom de l'établissement :  " +
                        feature.properties["etablissement_nom"] +
                        "<br>" +
                        "Type d'établissement :  " +
                        feature.properties["etablissement_categorie"] +
                        "<br>" +
                        "Public ou Privé? :  " +
                        feature.properties["etablissement_secteur"] +
                        "<br>" +
                        "Canton :  " +
                        feature.properties["canton_nom"] +
                        "<br>" +
                        "Effectif :  " +
                        feature.properties["eleve_nbr"] +
                        "<br>"
                        /*"<br>" +
                        "Élèves / Classe -  " +
                        (feature.properties["Eleves / Classe"] &&
                        parseInt(feature.properties["Eleves / Classe"], 10) > 0
                          ? parseInt(feature.properties["Eleves / Classe"], 10)
                          : "inconnu") +
                        (layerSelectionButtons.loggedIn
                          ? "<br>" +
                            "Contact Direction -  " +
                            feature.properties["Autorités/Contacts 1"]
                          : "")
                          */
                    )
                    .addTo(map);
            },
        });
        myLayer.addTo(map);
        return myLayer;
    },
};


export const EducationPrimaire = {
    slug: "educationPrimaire",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change
        const data = dataSource.educationInfo;
        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {
                //console.log(feature);
                const { checkbox10 } = layerSelectionButtons;


                //TBM?
                // instead of using a filter, just display non-selected items in grey
                if (checkbox10 === "ecole_primaire" && feature.properties["etablissement_categorie"] && feature.properties["etablissement_categorie"].toLowerCase().includes("primaire")) {

                    return L.marker(latlng, {icon: primaireIcon});
                }

            },
            onEachFeature: function (feature, layer) {
                layer
                    .bindPopup(
                        "Nom de l'établissement :  " +
                        feature.properties["etablissement_nom"] +
                        "<br>" +
                        "Type d'établissement :  " +
                        feature.properties["etablissement_categorie"] +
                        "<br>" +
                        "Public ou Privé? :  " +
                        feature.properties["etablissement_secteur"] +
                        "<br>" +
                        "Canton :  " +
                        feature.properties["canton_nom"] +
                        "<br>" +
                        "Effectif :  " +
                        feature.properties["eleve_nbr"] +
                        "<br>"
                        /*"<br>" +
                        "Élèves / Classe -  " +
                        (feature.properties["Eleves / Classe"] &&
                        parseInt(feature.properties["Eleves / Classe"], 10) > 0
                          ? parseInt(feature.properties["Eleves / Classe"], 10)
                          : "inconnu") +
                        (layerSelectionButtons.loggedIn
                          ? "<br>" +
                            "Contact Direction -  " +
                            feature.properties["Autorités/Contacts 1"]
                          : "")
                          */
                    )
                    .addTo(map);
            },
        });
        myLayer.addTo(map);
        return myLayer;
    },
};


export const EducationJardin = {
    slug: "educationJardin",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change
        const data = dataSource.educationInfo;
        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {
                //console.log(feature);
                const { checkbox11 } = layerSelectionButtons;


                //TBM?
                // instead of using a filter, just display non-selected items in grey
                if (checkbox11 === "jardin" && feature.properties["etablissement_categorie"] && feature.properties["etablissement_categorie"].toLowerCase().includes("jardin")) {

                    return L.marker(latlng, {icon: jardinIcon});
                }

            },
            onEachFeature: function (feature, layer) {
                layer
                    .bindPopup(
                        "Nom de l'établissement :  " +
                        feature.properties["etablissement_nom"] +
                        "<br>" +
                        "Type d'établissement :  " +
                        feature.properties["etablissement_categorie"] +
                        "<br>" +
                        "Public ou Privé? :  " +
                        feature.properties["etablissement_secteur"] +
                        "<br>" +
                        "Canton :  " +
                        feature.properties["canton_nom"] +
                        "<br>" +
                        "Effectif :  " +
                        feature.properties["eleve_nbr"] +
                        "<br>"
                        /*"<br>" +
                        "Élèves / Classe -  " +
                        (feature.properties["Eleves / Classe"] &&
                        parseInt(feature.properties["Eleves / Classe"], 10) > 0
                          ? parseInt(feature.properties["Eleves / Classe"], 10)
                          : "inconnu") +
                        (layerSelectionButtons.loggedIn
                          ? "<br>" +
                            "Contact Direction -  " +
                            feature.properties["Autorités/Contacts 1"]
                          : "")
                          */
                    )
                    .addTo(map);
            },
        });
        myLayer.addTo(map);
        return myLayer;
    },
};






export const LampadairesWorking = {
    slug: "lampadairesWorking",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change

        const data = dataSource.lampadaireInfo;
        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {
                const { checkbox12 } = layerSelectionButtons;

                //TBM?
                //console.log(feature.properties["probleme"]);
                // instead of using a filter, just display non-selected items in grey
                if (checkbox12 === "lampadairesWorking" && feature.properties["probleme"]===null ) {
                    //if (feature.properties["Type d'établissement 1"] === "Pré Scolaire") {

                    return  L.circleMarker(latlng, lampadaireWorkingColor);
                }


            },
            onEachFeature: function (feature, layer) {
                layer
                    .bindPopup(

                        "Probleme? :  " +
                        feature.properties["probleme"]+
                        "<br>"
                    )
                    .addTo(map);
            },
        });
        myLayer.addTo(map);
        return myLayer;
    },
};

export const LampadairesNotWorking = {
    slug: "lampadairesNotWorking",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change
        const data = dataSource.lampadaireInfo;
        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {
                const { checkbox13 } = layerSelectionButtons;

                //TBM?
                //console.log(feature.properties["ligne"]);
                // instead of using a filter, just display non-selected items in grey
                if (checkbox13 === "lampadairesNotWorking" && feature.properties["probleme"] !== null ) {
                    //if (feature.properties["Type d'établissement 1"] === "Pré Scolaire") {

                    return  L.circleMarker(latlng, lampadaireNotWorkingColor);
                }


            },
            onEachFeature: function (feature, layer) {
                layer
                    .bindPopup(

                        "Probleme :  " +
                        feature.properties["probleme"]+
                        "<br>"
                    )
                    .addTo(map);
            },
        });
        myLayer.addTo(map);
        return myLayer;
    },
};

/*****ADDING Prefectures LAYERS*****/

let bufferStyle = {
    fillColor: "yellow",
    fillOpacity: 0.4,

    color: "red",
    weight: 0,
    opacity: 1,
};

export const AddBTBuffer = {
    slug: "addBTBuffer",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change
        const data = dataSource.bufferInfo;
        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {




            },
            onEachFeature: function (feature, layer) {
                //console.log(feature);
                layer
                    .bindPopup(
                        "Préfecture :  " +feature.properties["prefecture"]+"<br>"+
                        "Commune :  " +feature.properties["commune"]+"<br>"
                    )
                    .addTo(map);
            },
            style: function (feature) {
                return bufferStyle;
            }
        });
        myLayer.addTo(map);
        return myLayer;
    },
};

/*****ADDING Prefectures LAYERS*****/

let prefectureLoadStyle = {
    fillColor: "blue",
    fillOpacity: 0.05,

    color: "#FFF",
    weight: 1.5,
    opacity: 1,
};

export const AddPrefectures = {
    slug: "addPrefectures",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change
        const data = dataSource.prefectureInfo;
        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {




            },
            onEachFeature: function (feature, layer) {
                //console.log(feature);

            },
            style: function (feature) {
                return prefectureLoadStyle;
            }
        });
        myLayer.addTo(map);
        return myLayer;
    },
};


export const AddCantons = {
    slug: "addCantons",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change
        const data = dataSource.cantonInfo;
        console.log(data);
        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {




            },
            onEachFeature: function (feature, layer) {
                //console.log(feature);

            },
            style: function (feature) {
                return prefectureLoadStyle;
            }
        });
        myLayer.addTo(map);
        return myLayer;
    },
};



/*****ADDING NEW PROJECT LAYERS*****/

let firstStyle = {
    fillColor: "#d7191c",
    fillOpacity: 0.6,

    color: "#FFF",
    weight: 1.5,
    opacity: 1,
};


let secondStyle = {
    fillColor: "#fdae61",
    fillOpacity: 0.6,

    color: "#FFF",
    weight: 1.5,
    opacity: 1,
};

let thirdStyle = {
    fillColor: "#ffffbf",
    fillOpacity: 0.6,

    color: "#FFF",
    weight: 1.5,
    opacity: 1,
};

let fourthStyle = {
    fillColor: "#a7daa0",
    fillOpacity: 0.6,

    color: "#FFF",
    weight: 1.5,
    opacity: 1,
};

let fithStyle = {
    fillColor: "#68a064",
    fillOpacity: 0.6,

    color: "#FFF",
    weight: 1.5,
    opacity: 1,
};

export const AddCouvertureExist = {
    slug: "addCouvertureExist",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change
        const data = dataSource.couvertureExistInfo;
        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {




            },
            onEachFeature: function (feature, layer) {
                layer
                    .bindPopup(
                        "Nom :  " +feature.properties.canton+"<br>"
                    )
                .addTo(map);//console.log(feature);

            },
            style: function (feature) {
                let accessPercentage = parseInt(feature.properties.op1_perc);
                if(accessPercentage>=0 && accessPercentage<20){
                    return firstStyle;
                }
                if(accessPercentage>=20 && accessPercentage<40){
                    return secondStyle;
                }
                if(accessPercentage>=40 && accessPercentage<60){
                    return thirdStyle;
                }
                if(accessPercentage>=60 && accessPercentage<80){
                    return fourthStyle;
                }
                if(accessPercentage>=80 && accessPercentage<=100){
                    return fithStyle;
                }
            }
        });
        myLayer.addTo(map);
        return myLayer;
    },
};


export const AddCouvertureFutur = {
    slug: "addCouvertureFutur",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change

        const data = dataSource.couvertureFuturInfo;
        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {




            },
            onEachFeature: function (feature, layer) {
                layer
                    .bindPopup(
                        "Nom :  " +feature.properties.canton+"<br>"
                    )
                .addTo(map);//console.log(feature);

            },
            style: function (feature) {
                let accessPercentage = parseInt(feature.properties.op2_perc);
                if(accessPercentage>=0 && accessPercentage<20){
                    return firstStyle;
                }
                if(accessPercentage>=20 && accessPercentage<40){
                    return secondStyle;
                }
                if(accessPercentage>=40 && accessPercentage<60){
                    return thirdStyle;
                }
                if(accessPercentage>=60 && accessPercentage<80){
                    return fourthStyle;
                }
                if(accessPercentage>=80 && accessPercentage<=100){
                    return fithStyle;
                }
            }
        });
        myLayer.addTo(map);
        return myLayer;
    },
};



let lampadairesWorkingColor = {
    fillColor: "green",
    fillOpacity: 1,
    color: "green",
    weight: 1,
    opacity: 1,
    radius: 3
};

let lampadairesNotWorkingColor = {
    fillColor: "red",
    fillOpacity: 1,
    color: "red",
    weight: 1,
    opacity: 1,
    radius: 3
};

let lampadairesNotKnownColor = {
    fillColor: "grey",
    fillOpacity: 1,
    color: "grey",
    weight: 1,
    opacity: 1,
    radius: 3
};

export const AddLampadairesEtat = {
    slug: "addLampadairesEtat",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change
        const data = dataSource.lampadairesEtatInfo;
        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {

                let stateLampadaire = feature.properties.ampoules_fonctionnelles_nbr_2_LV;
                if(stateLampadaire === "0"){
                    return  L.circleMarker(latlng, lampadairesNotWorkingColor);
                }
                if(stateLampadaire === "1"){
                    return  L.circleMarker(latlng, lampadairesWorkingColor);
                }
                if(stateLampadaire === "NSP"){
                    return  L.circleMarker(latlng, lampadairesNotKnownColor);
                }

                
            },
            onEachFeature: function (feature, layer) {
                //console.log(feature);

            },
            style: function (feature) {
                
               
            }
        });
        myLayer.addTo(map);
        return myLayer;
    },
};


let eduBufferStyle = {
    fillColor: "yellow",
    fillOpacity: 0.4,
    color: "#FFF",
    opacity: 0,
    weight: 0,
    radius: 5
};


const BTLineStyle = {
    color: '#2C75FF',
    weight: 2,
    opacity: .7,
    
}

const MTLineStyle = {
    color: '#6600FF',
    weight: 3,
    opacity: .7
}

export const AddLigneBtMt = {
    slug: "addLigneBtMt",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change
        const data = dataSource.ligneBtMtInfo;
        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {

            },
            onEachFeature: function (feature, layer) {
                //console.log(feature);

            },
            style: function (feature) {
                const style = { 
                    color: "rgb(40, 75, 117)" 
                };

                style["weight"] = "2";

                switch (feature.properties.ligne_type) {
                  case "BT":
                    //style["dashArray"] = "10, 10";
                   
                    return BTLineStyle;

                  case "MT":

                    return MTLineStyle;
                    
                  default:
                }

               //return style;
            }
        });
        myLayer.addTo(map);
        return myLayer;
    },
};



export const AddReseauActuelFutur = {
    slug: "addReseauActuelFutur",
    forceUpdate: true,
    addToMap: (map, dataSource, layerSelectionButtons) => {
        //TBM really not clear, why we do dataSource.educationInfo --> need change
        const data = dataSource.reseauActuelFuturInfo;
        let myLayer = L.geoJSON(data, {
            pointToLayer: function (feature, latlng) {

                return  L.circleMarker(latlng, lampadaireWorkingColor);


            },
            onEachFeature: function (feature, layer) {
                //console.log(feature);

            },
            style: function (feature) {
                return eduBufferStyle;
            }
        });
        myLayer.addTo(map);
        return myLayer;
    },
};

