class LoadDataTask {
  load = async (dataLoadInfo, mounted, setState) => {
    const { dataUrl, fieldName } = dataLoadInfo;

    if (mounted.val === false) return null;

    // check if we should remove this, notably the cors header
    const init = {
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    };

    return fetch(dataUrl, init)
      .then((response) => {
        return response.json();
      })
      .then((geojson_data) => {
        console.log("Geojson", geojson_data);

        setState((state) => {
          let newState = { ...state };
          newState[fieldName] = geojson_data;
          return newState;
        });
      })
      .catch((err) => {
        // Do something for an error here
        console.log("Error reading data - " + err);
      });
  };
}

export default LoadDataTask;
