import React, {useState,useEffect} from "react";
import "./Timeline.scss";
import Regions from "./regions/Regions";

import { sectorsList } from "../../services/sectorService";
import { regionRecap } from "../../services/recapService";
import Carousel from 'react-elastic-carousel';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const Timeline = () => {

    const [hide, setHide] = useState(false);
    const [hideMobile, setHideMobile] = useState(false);
    const [thesectors, setThesectors] = useState([]);
    const [regions, setRegions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=> {
        async function fetchRegions() {
            const res = await regionRecap();
            setRegions(res);
        }
        async function fetchSectors() {
            const resp = await sectorsList();
            setThesectors(resp);
        }


        fetchRegions().finally((e) => {
            setIsLoading(false);
        });
        fetchSectors();

    }, [])

    const sliceList = () => {
        setHide(true);
    }

    const unSliceList = () => {
        setHide(false);
    }

    const sliceListMobile = () => {
        setHideMobile(true);
    }

    const unSliceListMobile = () => {
        setHideMobile(false);
    }

    /* Detect screen size */

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="timeline">
            <h1>Progrès du recensement</h1>
            {
                isLoading ?
                    <div className="is-loading">
                        <span className="spinner-box">
                            <span className="spinner-box-inner"/>
                        </span>
                        <p>Chargement des données en cours...</p>
                    </div> :
                    (<div className="timeline-inner">
                        {
                            windowDimensions.width > 1024 ?
                                (
                                    <div className="timeline-inner-wrapper">
                                        <div className={"timeline-inner-wrapper-table " + (!hide ? '' : 'show')}>
                                            <div className="timeline-inner-wrapper-table-left">
                                                {
                                                    thesectors && thesectors.map((sector) => {
                                                        return (
                                                            <ul>
                                                                <li key={sector.id}>
                                                                    {sector.name} :
                                                                </li>
                                                            </ul>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="timeline-inner-wrapper-table-right">
                                                {
                                                    regions && regions.map((region) => {
                                                        return (
                                                            <Regions
                                                                key={region.id}
                                                                regionName={region.name}
                                                                sectors={region.sectors}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="timeline-inner-wrapper-read-more">
                                            <button className={!hide ? '' : 'hide'} onClick={sliceList}>Voir plus</button>
                                            <button className={hide ? '' : 'hide'} onClick={unSliceList}>Voir Moins</button>
                                        </div>
                                    </div>
                                ) : ('')
                        }
                        {
                            windowDimensions.width <= 1024 ? (
                                <div className={"carousel-wrapper " + (!hideMobile ? '' : 'show')}>
                                    <Carousel
                                        itemsToShow={2}
                                        showArrows={true}
                                        className="mobile-regions-wrapper"
                                        itemsToScroll={1}
                                        style={null}
                                        breakPoints={
                                            [
                                                {
                                                    width: 1,
                                                    itemsToShow: 1
                                                },
                                                {
                                                    width: 480,
                                                    itemsToShow: 2
                                                },
                                                {
                                                    width: 600,
                                                    itemsToShow: 3
                                                },
                                                {
                                                    width: 992,
                                                    itemsToShow: 4
                                                }
                                            ]
                                        }
                                    >
                                        {
                                            regions && regions.map((regions) => {
                                                return (
                                                    <Regions key={regions.id} regionName={regions.name}
                                                             sectors={regions.sectors}/>
                                                )
                                            })
                                        }
                                    </Carousel>
                                    <div className="carousel-wrapper-read-more">
                                        <button className={!hideMobile ? '' : 'hideMobile'} onClick={sliceListMobile}>Voir plus</button>
                                        <button className={hideMobile ? '' : 'hideMobile'} onClick={unSliceListMobile}>Voir Moins</button>
                                    </div>
                                </div>
                            ) : ("")
                        }
                    </div>)
            }
        </div>
    );
}

export default Timeline;
