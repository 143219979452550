import React, { useEffect, useState } from "react";
import { logout } from "../../../services/authentication-service";
import "./Header.scss";
import TogoLogo from "../../../assets/images/Togo-logo.png";
import { NavLink } from "react-router-dom";

const Header = () => {
  //const [anchorEl, setAnchorEl] = React.useState(null);
  //const open = Boolean(anchorEl);
  /*const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };*/

  const [isSticky, setSticky] = useState(false);
  const [click, setClick] = React.useState(false);

  const handleClick = () => {
    setClick(!click);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
          setSticky(window.pageYOffset > 70)
      );
    }
    document.body.classList.toggle("open", click);
  }, [click]);

  const logOut = () => {
    logout();
  };

  const userInfos = JSON.parse(localStorage.getItem('user'));

  /*console.log(JSON.parse(localStorage.getItem('user')));*/

  return (
      <header className={"header " + (isSticky ? " sticky" : "")}>
        <nav className="header-navbar" onClick={(e) => e.stopPropagation()}>
          <div className="header-navbar-inner">
            <div className="header-navbar-inner-left">
              <div className="header-navbar-inner-left-logo">
                <img src={TogoLogo} alt="Togo logo" />
              </div>
            </div>
            <div className="header-navbar-inner-right">
              <ul className={click ? "nav-side-menu active" : "nav-side-menu"}>
                <li>
                  <NavLink
                      exact
                      activeClassName="active"
                      to="/about-project"
                      onClick={click ? handleClick : null}
                  >
                    A propos du projet
                  </NavLink>
                </li>
                <li>
                  <NavLink
                      exact
                      activeClassName="active"
                      to="/tableau-de-bord"
                      onClick={click ? handleClick : null}
                  >
                    Tableau de bord
                  </NavLink>
                </li>
                {
                  userInfos.USER_ROLE === "ROLE_ADMIN" ? (
                      <span>
                         <li>
                              <NavLink
                                  exact
                                  activeClassName="active"
                                  to="/cartes"
                                  onClick={click ? handleClick : null}
                              >
                                Cartes
                              </NavLink>
                         </li>
                        <li>
                          <NavLink
                              exact
                              activeClassName="active"
                              to="/new-project/analyse"
                              onClick={click ? handleClick : null}
                          >
                            Indicateurs
                          </NavLink>
                        </li>
                      </span>
                  ) : ('')
                }
                {/*
                            <li>
                                <NavLink
                                    exact
                                    activeClassName="active"
                                    to="/about-mitsio-motu"
                                    onClick={click ? handleClick : null}
                                >
                                    A propos de Mitsio Motu
                                </NavLink>
                            </li>
                        */}
                <li className="logout">
                  <NavLink exact activeClassName="active" to="/" onClick={logOut}>
                    Déconnexion
                  </NavLink>
                </li>
              </ul>
              <div className="nav-icon" onClick={handleClick}>
                <button
                    id="menu-button"
                    className={click ? "" : "collapsed"}
                    type="button"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
        </nav>
      </header>
  );
};

export default Header;
