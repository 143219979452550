import React, { useState, useEffect, useRef } from "react";
import "./Map.scss";
import L from "leaflet";

import {
  baseLayers /*, countyPositions*/,
} from "../../../assets/constants/const.js";
import LoadDataTask from "../../../tasks/LoadDataTask.js";
import { selectedSectorData } from "../../../services/recapService";
import { sectorsList } from "../../../services/sectorService";

const Map = ({
               subdivisionLevel,
               selectedRegion,
               selectedPrefecture,
               selectedCommune,
               setSubdivisionLevel,
               setSelectedRegion,
               setSelectedPrefecture,
               setSelectedCommune,
             }) => {
  const [map, setMap] = useState(null);
  // layersInfo will contain the geojson info corresponding to a geographic area, like "regions"
  const [sectors, setSectors] = useState([]);
  const [layersInfo, setLayersInfo] = useState({});
  const [selectedSector, setSelectedSector] = useState(0);
  const [selectionData, setSelectionData] = useState([]);
  //const [allSectors, setAllSectors] = useState([]);
  let overlayLayers = useRef({
    regions: null,
    prefectures: null,
    communes: null,
  });

  //lighten(map-get($theme-colors, "green"), 60%);
  let toBeLaunchedStyle = {
    fillColor: "#ededed",
    fillOpacity: 1,

    color: "grey",
    weight: 2,
    opacity: 0.3,
  };

  let launchedStyle = {
    fillColor: "#d0f6eb",
    fillOpacity: 1,

    color: "grey",
    weight: 2,
    opacity: 0.3,
  };

  let inFinalisationStyle = {
    fillColor: "#3bd9a9",
    fillOpacity: 1,

    color: "grey",
    weight: 2,
    opacity: 0.3,
  };

  let finishedStyle = {
    fillColor: "#187C5E",
    fillOpacity: 1,

    color: "grey",
    weight: 2,
    opacity: 0.3,
  };



  const setUpMap = () => {
    //console.log("--START setUpMap--");
    const latLng = L.latLng(8, 2);
    const zoom = 7;
    const mapOptions = {
      minZoom: 7,
      maxZoom: 18,
      maxBounds: L.latLngBounds(L.latLng(4, -2), L.latLng(12.5, 5)),
    };
    const newMap = L.map("map", mapOptions).setView(latLng, zoom);
    setMap(newMap);
    //console.log("--END setUpMap--");
  };

  const addBaseLayersToMap = () => {
    const addLayerToMap = (infoLayer) => {
      var layer = L.tileLayer(infoLayer.urlTemplate, {
        attribution: infoLayer.options.attribution,
        maxZoom: infoLayer.options.maxZoom,
      });

      //console.log("addBaseLayersToMap - map: ", map);
      map.addLayer(layer);
      return layer;
    };

    var [infoBaselayer1, infoBaselayer2, infoBaselayer3] = baseLayers;

    var layer1 = addLayerToMap(infoBaselayer1);
    var layer2 = addLayerToMap(infoBaselayer2);
    var layer3 = addLayerToMap(infoBaselayer3);

    var baseMaps = {};
    baseMaps[infoBaselayer3.name] = layer3;
    baseMaps[infoBaselayer2.name] = layer2;
    baseMaps[infoBaselayer1.name] = layer1;

    var overlayMaps = {
      /*Those are added manually*/
    };

    L.control.layers(baseMaps, overlayMaps).addTo(map);
  };

  const addLatLngInfoOnMap = (e) => {
    if (e.originalEvent.shiftKey)
      L.popup()
          .setLatLng(e.latlng)
          .setContent(`${e.latlng.toString()}<br/>zoom level: ${+map.getZoom()}`)
          .openOn(map);
  };

  const loadData = () => {
    const dataLoadInfos = [
      {
        dataUrl: "/geodata/togo_admin_regions_v2.geojson",
        fieldName: "regions",
      },
      {
        dataUrl: "/geodata/togo_admin_prefectures_v2.geojson",
        fieldName: "prefectures",
      },
      {
        dataUrl: "/geodata/togo_admin_communes_v2.geojson",
        fieldName: "communes",
      },
    ];
    /* we use a mounted flag, to be sure to not update
      the state in the case the component has unmounted */
    let mounted = { val: true };
    const loadDataTask = new LoadDataTask();
    dataLoadInfos.forEach((info) => {
      loadDataTask.load(info, mounted, setLayersInfo);
    });
    //console.log("--END loadData--");
    return () => (mounted.val = false);
  };

  const updateSelectedAreaStyle = (style) => {
    let newStyle = { ...style };
    newStyle["color"] = "blue";
    newStyle["weight"] = 3;
    newStyle["opacity"] = 1;
    return newStyle;
  };

  /*****ADDING Regions LAYERS*****/
  const addRegions = () => {
    if (Object.entries(layersInfo).length === 0) {
      return;
    }

    let layerInfo = layersInfo["regions"];

    overlayLayers.current.regions = L.geoJSON(layerInfo, {
      onEachFeature: function (feature, layer) {
        // this needs to be cleaned up when the component will unmount
        //console.log(selectionData);
        layer.on("click", (event) => {
          //console.log(selectionData);
          setSelectedRegion(feature.properties.fid);
        });

        layer
            .bindTooltip(feature.properties["region"], {
              permanent: true,
              direction: "center",
              className: "polygon-labels polygon-labels-region",
            })
            .openTooltip();
      },
      style: function (feature) {
        console.log(feature);
        let zoneStyle = toBeLaunchedStyle;
        //console.log(selectionData);
        selectionData && selectionData.forEach((item)=>{
          //console.log(item);
          if(parseInt(item.id) === parseInt(feature.properties.fid)){
            switch (item.generalStatus && item.generalStatus.advancementStatus){
              case 'En cours':
                zoneStyle = launchedStyle;
                break;
              case 'En finalisation':
                zoneStyle = inFinalisationStyle;
                break;
              case 'Terminé':
                zoneStyle = finishedStyle;
                break;
              default:
                zoneStyle = toBeLaunchedStyle;
            }
            if (item.sectors) {
              for (var i = 0; i < item.sectors.length; i++) {
                if( parseInt(item.sectors[i].id) === parseInt(selectedSector) ){
                  switch (item.sectors[i].sectorStatus){
                    case 'En cours':
                      zoneStyle = launchedStyle;
                      break;
                    case 'En finalisation':
                      zoneStyle = inFinalisationStyle;
                      break;
                    case 'Terminé':
                      zoneStyle = finishedStyle;
                      break;
                    default:
                      zoneStyle = toBeLaunchedStyle;
                  }
                }
              }
            }
          }
        });

        if (selectedRegion === feature.properties.fid) {
          return updateSelectedAreaStyle(zoneStyle);
        } else{
          return zoneStyle;
        }
      },
    }).addTo(map);

    return () => {
      L.geoJSON(layerInfo, {
        onEachFeature: function (feature, layer) {
          // this needs to be cleaned up when the component will unmount
          layer.off("click", () => setSelectedRegion(feature.properties.fid));
        },
      });
    };

    //console.log("--END addRegions--");
  };

  /*****ADDING Prefectures LAYERS*****/
  const addPrefectures = () => {
    if (Object.entries(layersInfo).length === 0) return;

    let layerInfo = layersInfo["prefectures"];

    overlayLayers.current.prefectures = L.geoJSON(layerInfo, {
      onEachFeature: function (feature, layer) {
        // this needs to be cleaned up when the component will unmount

        layer.on("click", (event) => {
          //console.log(selectionData);
          setSelectedPrefecture(feature.properties.fid);
        });
        layer
            .bindTooltip(feature.properties["prefecture"], {
              permanent: true,
              direction: "center",
              className: "polygon-labels polygon-labels-prefecture",
            })
            .openTooltip();

      },
      style: function (feature) {

        let zoneStyle = toBeLaunchedStyle;
        selectionData && selectionData.forEach((item)=>{
          if(parseInt(item.id) === parseInt(feature.properties.fid)){
            switch (item.generalStatus.advancementStatus){
              case 'En cours':
                zoneStyle = launchedStyle;
                break;
              case 'En finalisation':
                zoneStyle = inFinalisationStyle;
                break;
              case 'Terminé':
                zoneStyle = finishedStyle;
                break;
              default:
                zoneStyle = toBeLaunchedStyle;
            }
            if (item.sectors) {

              for (var i = 0; i < item.sectors.length; i++) {
                if( parseInt(item.sectors[i].id) === parseInt(selectedSector) ){

                  switch (item.sectors[i].sectorStatus){
                    case 'En cours':
                      zoneStyle = launchedStyle;
                      break;
                    case 'En finalisation':
                      zoneStyle = inFinalisationStyle;
                      break;
                    case 'Terminé':
                      zoneStyle = finishedStyle;
                      break;
                    default:
                      zoneStyle = toBeLaunchedStyle;
                  }
                }
              }
            }
          }
        });
        if (selectedPrefecture === feature.properties.fid) {
          return updateSelectedAreaStyle(zoneStyle);
        } else return zoneStyle;
      },
    }).addTo(map);

    return () => {
      L.geoJSON(layerInfo, {
        onEachFeature: function (feature, layer) {
          // this needs to be cleaned up when the component will unmount
          layer.off("click", () =>
              setSelectedPrefecture(feature.properties.fid)
          );
        },
      });
    };

    //console.log("--END addPrefectures--");
  };


  /*****ADDING Communes LAYERS*****/
  const addCommunes = () => {
    if (Object.entries(layersInfo).length === 0) return;

    let layerInfo = layersInfo["communes"];

    overlayLayers.current.communes = L.geoJSON(layerInfo, {
      onEachFeature: function (feature, layer) {
        // this needs to be cleaned up when the component will unmount

        layer.on("click", (event) => {
          //console.log(selectionData);
          setSelectedCommune(feature.properties.fid);
        });
  /*
        layer
            .bindTooltip(feature.properties["commune"], {
              permanent: true,
              direction: "center",
              className: "polygon-labels polygon-labels-prefecture",
            })
            .openTooltip();
  */

      },
      style: function (feature) {
        //console.log('selected sectos: -------'+selectedSector);
        let zoneStyle = toBeLaunchedStyle;
        selectionData && selectionData.forEach((item)=>{
          if(parseInt(item.id) === parseInt(feature.properties.fid)){
            switch (item.generalStatus.advancementStatus){
              case 'En cours':
                zoneStyle = launchedStyle;
                break;
              case 'En finalisation':
                zoneStyle = inFinalisationStyle;
                break;
              case 'Terminé':
                zoneStyle = finishedStyle;
                break;
              default:
                zoneStyle = toBeLaunchedStyle;
            }
            if (item.sectors) {
              for (var i = 0; i < item.sectors.length; i++) {
                
                if( parseInt(item.sectors[i].id) === parseInt(selectedSector) ){
                  //console.log(item.sectors[i]);
                  switch (item.sectors[i].sectorStatus){
                    case 'En cours':
                      zoneStyle = launchedStyle;
                      break;
                    case 'En finalisation':
                      zoneStyle = inFinalisationStyle;
                      break;
                    case 'Terminé':
                      zoneStyle = finishedStyle;
                      break;
                    default:
                      zoneStyle = toBeLaunchedStyle;
                  }
                }
              }
            }
          }
        });
        if (selectedCommune === feature.properties.fid) {
          return updateSelectedAreaStyle(zoneStyle);
        } else return zoneStyle;
      },
    }).addTo(map);

    return () => {
      L.geoJSON(layerInfo, {
        onEachFeature: function (feature, layer) {
          // this needs to be cleaned up when the component will unmount
          layer.off("click", () =>
              setSelectedCommune(feature.properties.fid)
          );
        },
      });
    };

    //console.log("--END addPrefectures--");
  };

 


  const overlayLayersDisplayManagement = () => {
    //Remove old displayed layer
    if (map) {
      if (overlayLayers.current.regions !== null) {
        overlayLayers.current.regions.removeFrom(map);
        overlayLayers.current.regions = null;
      }

      if (overlayLayers.current.prefectures !== null) {
        overlayLayers.current.prefectures.removeFrom(map);
        overlayLayers.current.prefectures = null;
      }

      if (overlayLayers.current.communes !== null) {
        overlayLayers.current.communes.removeFrom(map);
        overlayLayers.current.communes = null;
      }
    }

    //Add new displayed layer
    if (subdivisionLevel === "region") addRegions();
    else if (subdivisionLevel === "prefecture") addPrefectures();
    else if (subdivisionLevel === "commune") addCommunes();
  };

  const getSectorData = () => {
    //console.log('In Map: '+subdivisionLevel+'--->'+selectedSector);
    selectedSectorData(subdivisionLevel, selectedSector).then((res) => {
      //console.log(res);
      setSelectionData(res);
    });

  };

  useEffect(
      setUpMap,
      // eslint-disable-next-line
      []
  );
  useEffect(()=>{
        async function fetchSectors() {
          const resp = await sectorsList();
          setSectors(resp);
        }
        fetchSectors();
        loadData();
      },

      // eslint-disable-next-line
      []
  );

  useEffect(()=>{
    overlayLayersDisplayManagement();
  });

  useEffect( ()=>{
        async function fetchSectorData() {
          await getSectorData();
          overlayLayersDisplayManagement();
        }
        fetchSectorData();
      },

      // eslint-disable-next-line
      [
        layersInfo,
        subdivisionLevel,
        selectedRegion,
        selectedPrefecture,
        selectedCommune,
        selectedSector,
      ]
  );



  useEffect(
      () => {
        if (map !== null) {
          addBaseLayersToMap();
          map.on("click", addLatLngInfoOnMap);
        }

        return () => {
          if (map !== null) {
            map.off("click", addLatLngInfoOnMap);
          }
        };
      },
      // eslint-disable-next-line
      [map]
  );


  return (
      <div className="map-box">

        { loadData ? (
        <div className="map-box-content">
          <div id="map" />
          <div className="map-box-content-fixed">
            <div className="map-box-content-fixed-item">
              <p>Echelle</p>
              <ul>
                <li
                    className={subdivisionLevel === "region" ? "active" : ""}
                    onClick={() => setSubdivisionLevel("region")}
                >
                  Régions
                </li>
                <li
                    className={subdivisionLevel === "prefecture" ? "active" : ""}
                    onClick={() => setSubdivisionLevel("prefecture")}
                >
                  Préfecture
                </li>
                <li
                    className={subdivisionLevel === "commune" ? "active" : ""}
                    onClick={() => setSubdivisionLevel("commune")}
                >
                  Commune
                </li>
                {/*<li>Canton</li>*/}
              </ul>
            </div>
            {
              <div className="map-box-content-fixed-item">
                <p>Secteur(s)</p>
                <div className="map-box-content-fixed-item-select">
                  <select
                      name="sector"
                      id="sector"
                      value={selectedSector}
                      onChange={(event) => setSelectedSector(event.target.value)}
                  >
                    {/* @Kwaku - probably useful to put that in a dedicated file */}
                    <option value="0">-- Aucun Secteur --</option>
                    {
                      sectors && sectors.map((sector) => {
                        return (
                            <option value={sector.id}>{sector.name}</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>
            }
          </div>
          <div className="map-box-content-legend">
            <ul className="map-box-content-legend-item">
              <li>
                <span/>
              </li>
              <li>
                <span>A lancer</span>
              </li>
            </ul>
            <ul className="map-box-content-legend-item">
              <li>
                <span/>
              </li>
              <li>
                <span>En cours</span>
              </li>
            </ul>
            <ul className="map-box-content-legend-item">
              <li>
                <span/>
              </li>
              <li>
                <span>En finalisation</span>
              </li>
            </ul>
            <ul className="map-box-content-legend-item">
              <li>
                <span/>
              </li>
              <li>
                <span>Terminé</span>
              </li>
            </ul>
          </div>
        </div>
        

          ) : (
              <div>
                chargement
              </div>
          )
        }
      </div>
  );
};

export default Map;
