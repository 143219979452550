import React, { useEffect, useState } from "react";
import "./education.scss";
import LoadDataTask from "../../../../services/cartes/LoadDataTask";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormControlLabel from "@mui/material/FormControlLabel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@mui/material/Autocomplete";
import {
  baseLayers,
  countyPositions,
  regionsPrefectureList,
} from "../../../../assets/constants/const";
import TextField from "@mui/material/TextField";
import Map from "../../../carte-pages/carte-map/Mapt";
import education from "../../../../assets/images/education1.jpg";

import {
  dataLoadInfos,
  EducationUniversite,
  EducationLycee,
  EducationCollege,
  EducationPrimaire,
  EducationJardin,
  EcoleElectrifiee,
  EcoleNonElectrifiee,
  LigneBT,
  LigneMT,
  PoteauBT,
  PoteauMT,
  LampadairesWorking,
  LampadairesNotWorking,
  AddPrefectures,
  AddCantons,
  AddBTBuffer,
  //Here the imports for the new prject
  AddEduAccessEcole,
  AddEduEleveProf,
  AddeduEleveSansBat,
  AddEduAccessEcoleCanton,
  AddEduEcolePoint,
  AddSchoolWithoutToilets,
  AddSchoolWithoutElectricityLightning,
  AddVolontaryTeachers,
  AddPopulation,
} from "./layers";
import SideMenu from "../../side-menu/sideMenu";
import analyseIcon from "../../../../assets/images/icons/analyse.svg";
import FormControl from "@material-ui/core/FormControl";
//import InputLabel from "@material-ui/core/InputLabel";
//import Select from "@material-ui/core/Select";
//import MenuItem from "@material-ui/core/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
//import energy from "../../../../assets/images/energie.jpeg";

const EducationNew = () => {
  const [subdivisionLevel, setSubdivisionLevel] = useState("canton");
  const [selectedRegion, setSelectedRegion] = useState(0);
  const [selectedPrefecture, setSelectedPrefecture] = useState(0);
  const [selectedCommune, setSelectedCommune] = useState(0);
  //const [selectedLayer, setSelectedLayer] = useState(0);

  const [regionSelected, setRegionSelected] = useState(null);
  const [regionSelectedPrefectures, setRegionSelectedPrefectures] =
    useState(null);
  //const [prefectureSelected, setPrefectureSelected] = useState(null);

  //const [selectedPref, setSelectedPref] = useState(-1);

  const [mapData, setMapData] = useState({});
  const [selectedCounty, setSelectedCounty] = useState(0);
  const [radioVal, setRadioVal] = useState("type");
  const [checkbox0, setCheckbox0] = useState("canton");
  const [checkbox20, setCheckbox20] = useState("");
  const [checkbox30, setCheckbox30] = useState("");
  const [checkbox31, setCheckbox31] = useState("");
  const [checkbox1, setCheckbox1] = useState("");
  const [checkbox2, setCheckbox2] = useState("");
  const [checkbox3, setCheckbox3] = useState("");
  const [checkbox4, setCheckbox4] = useState("");
  const [checkbox5, setCheckbox5] = useState("");
  const [checkbox6, setCheckbox6] = useState("");
  const [checkbox7, setCheckbox7] = useState("");
  const [checkbox8, setCheckbox8] = useState("");
  const [checkbox9, setCheckbox9] = useState("");
  const [checkbox10, setCheckbox10] = useState("");
  const [checkbox11, setCheckbox11] = useState("");
  const [checkbox12, setCheckbox12] = useState("");
  const [checkbox13, setCheckbox13] = useState("");

  // here the states for the new project

  const [radioEcole, setRadioEcole] = useState("");
  const [radioEcoleLocal, setRadioEcoleLocal] = useState("");
  const [catonButtonClicked, setCatonButtonClicked] = useState(false);

  const layers = [
    AddPopulation,
    EducationUniversite,
    EducationLycee,
    EducationCollege,
    EducationPrimaire,
    EducationJardin,
    EcoleElectrifiee,
    EcoleNonElectrifiee,
    LampadairesWorking,
    LampadairesNotWorking,
    LigneBT,
    LigneMT,
    PoteauBT,
    PoteauMT,
    AddPrefectures,
    AddCantons,
    AddBTBuffer,
    AddEduAccessEcole,
    AddEduEleveProf,
    AddeduEleveSansBat,
    AddEduAccessEcoleCanton,
    AddEduEcolePoint,
    AddSchoolWithoutToilets,
    AddSchoolWithoutElectricityLightning,
    AddVolontaryTeachers,
  ];

  const layerSelectionButtons = {
    radioVal,
    setRadioVal,
    checkbox1,
    setCheckbox1,
    checkbox2,
    setCheckbox2,
    checkbox3,
    setCheckbox3,
    checkbox4,
    setCheckbox4,
    checkbox5,
    setCheckbox5,
    checkbox6,
    setCheckbox6,
    checkbox7,
    setCheckbox7,
    checkbox8,
    setCheckbox8,
    checkbox9,
    setCheckbox9,
    checkbox10,
    setCheckbox10,
    checkbox11,
    setCheckbox11,
    checkbox12,
    setCheckbox12,
    checkbox13,
    setCheckbox13,
    checkbox0,
    setCheckbox0,
    checkbox20,
    setCheckbox20,
    checkbox30,
    setCheckbox30,
    checkbox31,
    setCheckbox31,
  };

  const displayedLayers = {
    [AddCantons.slug]: checkbox0,
    [AddBTBuffer.slug]: checkbox20,
    [PoteauBT.slug]: checkbox1,
    [PoteauMT.slug]: checkbox2,
    //[PoteauHT.slug]: checkbox3,
    [LigneBT.slug]: checkbox4,
    [LigneMT.slug]: checkbox5,
    //[LigneHT.slug]: checkbox6,
    [AddBTBuffer.slug]: checkbox20,
    [EducationUniversite.slug]: checkbox7,
    [EducationLycee.slug]: checkbox8,
    [EducationCollege.slug]: checkbox9,
    [EducationPrimaire.slug]: checkbox10,
    [EducationJardin.slug]: checkbox11,
    [LampadairesWorking.slug]: checkbox12,
    [LampadairesNotWorking.slug]: checkbox13,
    [EcoleElectrifiee.slug]: checkbox30,
    [EcoleNonElectrifiee.slug]: checkbox31,
    [AddEduAccessEcole.slug]: radioEcole === "access_ecole_per_canton",
    [AddEduEleveProf.slug]: radioEcole === "eleve_per_class",
    [AddeduEleveSansBat.slug]: radioEcole === "eleve_sans_class",
    [AddEduAccessEcoleCanton.slug]: catonButtonClicked === true,
    [AddEduEcolePoint.slug]: catonButtonClicked === true,
    [AddSchoolWithoutToilets.slug]: radioEcoleLocal === "ecole_sans_toilette",
    [AddSchoolWithoutElectricityLightning.slug]:
      radioEcoleLocal === "ecole_sans_electricite_ni_eclairage",
    [AddVolontaryTeachers.slug]: radioEcole === "professeurs_volontaires",
    [AddPopulation.slug]: radioEcoleLocal === "population",
  };

  //const [expanded, setExpanded] = React.useState('panel1');
  /*
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
*/
  /* BELOW, the functions don't change for any sector */

  const loadData = function () {
    let mounted = { val: true };
    const loadDataTask = new LoadDataTask();
    dataLoadInfos.forEach((info) => {
      loadDataTask.load(info, mounted, setMapData);
    });

    console.log(mapData);
    return () => (mounted.val = false);
  };

  const handleChangeRadio = (event) => {
    if (event.target.checked === true) {
      console.log();
      let selectedValue = event.target.defaultValue;
      console.log(selectedValue);
      setRadioEcole(selectedValue);
    } else {
      setRadioEcole("");
    }
  };

  const handleChangeRadioLocal = (event) => {
    if (event.target.checked === true) {
      console.log();
      let selectedValue = event.target.defaultValue;
      console.log(selectedValue);
      setRadioEcoleLocal(selectedValue);
    } else {
      setRadioEcoleLocal("");
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  //const [educationLevel, setEducationLevel] = React.useState('');
  /*
    const handleChangeEducationLevel = (event) => {
        setEducationLevel(event.target.value);
    };
*/
  /*const [state, setState] = React.useState("");

  const handleChangeCheckbox = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };*/

  const [isHideCouvrage, setHideCouvrage] = useState("false");
  const [isHideIndicator, setHideIndicator] = useState("false");
  const handleRadioEcole = () => {
    //setRadioEcole("");
    setCatonButtonClicked(false);
    setRadioEcoleLocal("");
  };

  const handleCantonButtonClicked = () => {
    setRadioEcole("");
    setCatonButtonClicked(true);
    setSelectedCounty(14);
  };

  return (
    <div>
      {/*<Header/>*/}
      <div className="education-container">
        <div className="education-container-menu">
          <SideMenu />
        </div>
        <div className="education-container-sidebar">
          <div className="education-container-sidebar-title">
            <h2>
              <img src={analyseIcon} alt="icon" />
              <span>Indicateurs</span>
            </h2>
          </div>
          <p>
            Basés sur les données dans le cadre du PRISE, cette section propose
            des analyses et indicateurs clés pour certains secteurs dans la
            région des Savanes.
          </p>
          <div className="education-container-sidebar-list">
            <div className="education-container-sidebar-list-item">
              <img src={education} alt="education" />
              {/*<div className="education-container-sidebar-list-item-placeholder">
                                <h4>placeholder</h4>
                            </div>*/}
              <div className="education-container-sidebar-list-item-text">
                <h5>Éducation</h5>
                <p>
                  Accessibilité des écoles primaires, indicateurs sur la taille
                  des classes et conditions d'apprentissage.
                </p>
              </div>
            </div>
          </div>
          <div className="education-container-sidebar-filter">
            <div className="education-container-sidebar-filter-item">
              <ul>
                <li>
                  <button
                    className={isHideIndicator ? "selected" : ""}
                    onClick={() => {
                      handleRadioEcole();
                      setHideIndicator(true);
                      setHideCouvrage(true);
                    }}
                  >
                    Région
                  </button>
                </li>
                <li>
                  <button
                    className={!isHideCouvrage ? "selected" : ""}
                    onClick={() => {
                      handleCantonButtonClicked();
                      setHideIndicator(false);
                      setHideCouvrage(false);
                    }}
                  >
                    Local
                  </button>
                </li>
              </ul>
            </div>
            {/*<div className="education-container-sidebar-filter-item">
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    Catégories d'établissement
                                </InputLabel>
                                <Select
                                    labelId="education-level-select-label"
                                    id="demo-simple-select"
                                    value={educationLevel}
                                    label="Catégories d'établissement"
                                    onChange={handleChangeEducationLevel}
                                >
                                    <MenuItem value="universite">Université</MenuItem>
                                    <MenuItem value="lycee">Lycée</MenuItem>
                                    <MenuItem value="college">Collège</MenuItem>
                                    <MenuItem value="primaire">Primaire</MenuItem>
                                    <MenuItem value="maternalle">Maternelle</MenuItem>
                                </Select>
                            </FormControl>
                        </div>*/}
            <div className={"education-container-sidebar-filter-item"}>
              <div
                className={
                  "education-container-sidebar-filter-item-checklist " +
                  (!isHideCouvrage ? "" : "hide")
                }
              >
                <div className="education-container-sidebar-filter-item-checklist-item">
                  <p>Couverture</p>
                  <ul className="canvas-wrapper">
                    <li>
                      <span
                        className="polygon"
                        style={{ backgroundColor: "yellow" }}
                      />
                    </li>
                    <li>
                      <span className="text">Rayon d’accessibilité</span>
                    </li>
                  </ul>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      <div className="radio-group-item">
                        <FormControlLabel
                          value="population"
                          control={
                            <Radio
                              value="population"
                              onChange={handleChangeRadioLocal}
                            />
                          }
                          label="Population"
                        />

                        <span className="indicator-color-bis">
                          <span />
                        </span>
                      </div>
                      <div className="radio-group-item">
                        <FormControlLabel
                          value="withoutToilets"
                          control={
                            <Radio
                              value="ecole_sans_toilette"
                              onChange={handleChangeRadioLocal}
                            />
                          }
                          label="Écoles sans toilettes"
                        />
                        <span className="indicator-color">
                          <span />
                        </span>
                      </div>
                      <div className="radio-group-item">
                        <FormControlLabel
                          value="withoutElectricityAndLightning"
                          control={
                            <Radio
                              value="ecole_sans_electricite_ni_eclairage"
                              onChange={handleChangeRadioLocal}
                            />
                          }
                          label="Écoles sans électricité et éclairage"
                        />
                        <span className="indicator-color">
                          <span />
                        </span>
                      </div>
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="education-container-sidebar-filter-item-checklist-item">
                  <p>Établissements scolaires</p>
                  <ul className="canvas-wrapper">
                    <li>
                      <span className="circle" />
                    </li>
                    <li>
                      <span className="text">Primaires</span>
                    </li>
                  </ul>
                </div>
                {/*<div className="education-container-sidebar-filter-item-checklist-item">
                                    <p>Établissements scolaires</p>
                                    <FormControlLabel
                                        control={
                                            <Checkbox onChange={handleChangeCheckbox} name="Préscolaire" />
                                        }
                                        label="Préscolaire"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox onChange={handleChangeCheckbox} name="Primaire" />
                                        }
                                        label="Primaire"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox onChange={handleChangeCheckbox} name="Secondaire I et II" />
                                        }
                                        label="Secondaire I et II"
                                    />
                                </div>
                                <div className="education-container-sidebar-filter-item-checklist-item">
                                    <p>Bâtiments</p>
                                    <FormControlLabel
                                        control={
                                            <Checkbox onChange={handleChangeCheckbox} name="Dans le radius d’accessibilité" />
                                        }
                                        label="Dans le radius d’accessibilité"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox onChange={handleChangeCheckbox} name="Hors du radius d’accessibilité" />
                                        }
                                        label="Hors du radius d’accessibilité"
                                    />
                                </div>*/}
              </div>
              <div
                className={
                  "education-container-sidebar-filter-item-indicator " +
                  (!isHideIndicator ? "hide" : "")
                }
              >
                <p>Indicateurs</p>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    <div className="radio-group-item">
                      <FormControlLabel
                        value="byClass"
                        control={
                          <Radio
                            value="access_ecole_per_canton"
                            onChange={handleChangeRadio}
                          />
                        }
                        label="Population < 1,5km d’une école primaire (est. %)"
                      />
                      <span className="indicator-color">
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                      </span>
                    </div>
                    <div className="radio-group-item">
                      <FormControlLabel
                        value="withoutClass"
                        control={
                          <Radio
                            value="eleve_per_class"
                            onChange={handleChangeRadio}
                          />
                        }
                        label="Nb moyen d’eleve par classe en cycle primaire (Moy)"
                      />
                      <span className="indicator-color">
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                      </span>
                    </div>
                    <div className="radio-group-item">
                      <FormControlLabel
                        value="withoutClass"
                        control={
                          <Radio
                            value="eleve_sans_class"
                            onChange={handleChangeRadio}
                          />
                        }
                        label="Élèves du cycle primaire sans bâtiment (Abs)"
                      />
                      <span className="indicator-color">
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                      </span>
                    </div>

                    <div className="radio-group-item">
                      <FormControlLabel
                        value="volontaryTeacher"
                        control={
                          <Radio
                            value="professeurs_volontaires"
                            onChange={handleChangeRadio}
                          />
                        }
                        label="Professeurs volontaires ou parents d’élèves (%)"
                      />
                      <span className="indicator-color">
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                      </span>
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div className="cartes-wrapper-map">
          <div className="cartes-wrapper-map-echelle">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="echelle1a-content"
                id="echelle1a-header"
              >
                <Typography>Echelle</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="accordion-details-list">
                  <div className="accordion-details-list-item">
                    <Autocomplete
                      id="regions"
                      noOptionsText={"Aucune région"}
                      options={regionsPrefectureList}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Régions"
                          variant="outlined"
                        />
                      )}
                      value={regionSelected}
                      onChange={(event, newRegion) => {
                        setRegionSelected(newRegion);
                        newRegion &&
                          setRegionSelectedPrefectures(newRegion.prefectures);
                        if (!newRegion) setSelectedCounty(0);
                      }}
                    />
                  </div>
                  {regionSelectedPrefectures && regionSelected ? (
                    <div className="accordion-details-list-item">
                      <Autocomplete
                        id="prefectures"
                        noOptionsText={"Aucune préfecture"}
                        options={regionSelectedPrefectures.map(
                          (option) => option.id + "-" + option.name
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Préfectures"
                            variant="outlined"
                          />
                        )}
                        onChange={(event, newPrefecture) => {
                          newPrefecture &&
                            setSelectedCounty(newPrefecture.split("-")[0]);
                          if (!newPrefecture) setSelectedCounty(0);
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          {!isHideIndicator ? (
            ""
          ) : (
            <div className="cartes-wrapper-map-legend">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="legend-content"
                  id="legend-header"
                >
                  <Typography>Légende</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="cartes-wrapper-map-legend-list">
                    <div className="cartes-wrapper-map-legend-list-header">
                      <h3>
                        Population &lt; 1,5km d’une école primaire (est. %)
                      </h3>
                    </div>
                    <div className="cartes-wrapper-map-legend-list-content">
                      <ul>
                        <li className="rectangle">
                          <span style={{ backgroundColor: "#d7191c" }} />
                        </li>
                        <li className="name">49 - 60</li>
                      </ul>
                      <ul>
                        <li className="rectangle">
                          <span style={{ backgroundColor: "#fdae61" }} />
                        </li>
                        <li className="name">60 - 70</li>
                      </ul>
                      <ul>
                        <li className="rectangle">
                          <span style={{ backgroundColor: "#ffffbf" }} />
                        </li>
                        <li className="name">70 - 80</li>
                      </ul>
                      <ul>
                        <li className="rectangle">
                          <span style={{ backgroundColor: "#a7daa0" }} />
                        </li>
                        <li className="name">80 - 90</li>
                      </ul>
                      <ul>
                        <li className="rectangle">
                          <span style={{ backgroundColor: "#68a064" }} />
                        </li>
                        <li className="name">90 - 100</li>
                      </ul>
                    </div>
                    <div className="cartes-wrapper-map-legend-list-header">
                      <h3>
                        Nb moyen d’eleve par classe en cycle primaire (Moy)
                      </h3>
                    </div>
                    <div className="cartes-wrapper-map-legend-list-content">
                      <ul>
                        <li className="rectangle">
                          <span style={{ backgroundColor: "#d7191c" }} />
                        </li>
                        <li className="name">40 - 49</li>
                      </ul>
                      <ul>
                        <li className="rectangle">
                          <span style={{ backgroundColor: "#fdae61" }} />
                        </li>
                        <li className="name">35 - 40</li>
                      </ul>
                      <ul>
                        <li className="rectangle">
                          <span style={{ backgroundColor: "#ffffbf" }} />
                        </li>
                        <li className="name">30 - 35</li>
                      </ul>
                      <ul>
                        <li className="rectangle">
                          <span style={{ backgroundColor: "#a7daa0" }} />
                        </li>
                        <li className="name">25 - 30</li>
                      </ul>
                      <ul>
                        <li className="rectangle">
                          <span style={{ backgroundColor: "#68a064" }} />
                        </li>
                        <li className="name">18 - 25</li>
                      </ul>
                    </div>

                    <div className="cartes-wrapper-map-legend-list-header">
                      <h3>Élèves du cycle primaire sans bâtiment (Abs)</h3>
                    </div>
                    <div className="cartes-wrapper-map-legend-list-content">
                      <ul>
                        <li className="rectangle">
                          <span style={{ backgroundColor: "#d7191c" }} />
                        </li>
                        <li className="name">> 91</li>
                      </ul>
                      {/* <ul>
                                            <li className="rectangle"><span style={{backgroundColor: '#fdae61'}}/></li>
                                            <li className="name">20 - 40</li>
                                        </ul>*/}
                      <ul>
                        <li className="rectangle">
                          <span style={{ backgroundColor: "#ffffbf" }} />
                        </li>
                        <li className="name">42 - 91</li>
                      </ul>
                      <ul>
                        <li className="rectangle">
                          <span style={{ backgroundColor: "#a7daa0" }} />
                        </li>
                        <li className="name">1 - 42</li>
                      </ul>
                      <ul>
                        <li className="rectangle">
                          <span style={{ backgroundColor: "#68a064" }} />
                        </li>
                        <li className="name">0</li>
                      </ul>
                    </div>

                    <div className="cartes-wrapper-map-legend-list-header">
                      <h3>Écoles sans toilettes</h3>
                    </div>
                    <div className="cartes-wrapper-map-legend-list-content">
                      <ul>
                        <li className="rectangle">
                          <span style={{ backgroundColor: "#ba1b1b" }} />
                        </li>
                        <li className="name">> 91</li>
                      </ul>
                    </div>

                    <div className="cartes-wrapper-map-legend-list-header">
                      <h3>Écoles sans électricité et éclairage</h3>
                    </div>
                    <div className="cartes-wrapper-map-legend-list-content">
                      <ul>
                        <li className="rectangle">
                          <span style={{ backgroundColor: "#ba1b1b" }} />
                        </li>
                        <li className="name">> 91</li>
                      </ul>
                    </div>

                    <div className="cartes-wrapper-map-legend-list-header">
                      <h3>Professeurs volontaires ou parents d’élèves (%)</h3>
                    </div>
                    <div className="cartes-wrapper-map-legend-list-content">
                      <ul>
                        <li className="rectangle">
                          <span style={{ backgroundColor: "#fff5f0" }} />
                        </li>
                        <li className="name">0 - 20</li>
                      </ul>
                      <ul>
                        <li className="rectangle">
                          <span style={{ backgroundColor: "#fca487" }} />
                        </li>
                        <li className="name">20 - 40</li>
                      </ul>
                      <ul>
                        <li className="rectangle">
                          <span style={{ backgroundColor: "#ed392b" }} />
                        </li>
                        <li className="name">40 - 60</li>
                      </ul>
                      <ul>
                        <li className="rectangle">
                          <span style={{ backgroundColor: "#ba1b1b" }} />
                        </li>
                        <li className="name">60 - 64,9</li>
                      </ul>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
          <Map
            subdivisionLevel={subdivisionLevel}
            setSubdivisionLevel={setSubdivisionLevel}
            selectedRegion={selectedRegion}
            setSelectedRegion={setSelectedRegion}
            selectedPrefecture={selectedPrefecture}
            setSelectedPrefecture={setSelectedPrefecture}
            selectedCommune={selectedCommune}
            setSelectedCommune={setSelectedCommune}
            layers={layers}
            displayedLayers={displayedLayers}
            mapData={mapData}
            latLng={countyPositions[selectedCounty].latLng}
            zoom={countyPositions[selectedCounty].zoom}
            baseLayers={baseLayers}
            layerSelectionButtons={layerSelectionButtons}
          />
        </div>
      </div>
      {/*<Footer/>*/}
    </div>
  );
};

export default EducationNew;
