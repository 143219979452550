import React, {useEffect, useState} from 'react';
import './Regions.scss';
/*import ProgressBar from "@ramonak/react-progress-bar";*/
import ProgressBar from "../progress-bar/progress-bar";
import ProgressBarMobile from "../progress-bar/progress-bar-mobile";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const Regions = ({ regionName, sectors }) => {

    /*console.log(sectors);*/

    /* Detect screen size */

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="region-item">
            <div className="region-item-title">
                <h2>{regionName}</h2>
            </div>
            <div className="region-item-percent">
                {
                    sectors && sectors.map((sector) => {
                        return (
                            <div>
                                {
                                    windowDimensions.width > 1024 ? (
                                        <ProgressBar key={sector.id} percent={sector.sectorPercent} />
                                    ) : (
                                        <ProgressBarMobile key={sector.id} percent={sector.sectorPercent} sectorName={sector.name} />
                                    )
                                }
                            </div>
                            /*<ProgressBar className={"progress" + (sector.sectorPercent > 0 ? ' start' : '')} completed={sector.sectorPercent} maxCompleted={100} />*/
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Regions;
