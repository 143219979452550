import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

function ProtectedRoute(props){
	const history = useHistory();
	let Component = props.component
	useEffect(()=>{
		if(!localStorage.getItem("logedIn")){
			history.push('/');
		}
	},
	// eslint-disable-next-line
	[])
	 
	return(
		
			<Component {...props} />
	)
}

export default ProtectedRoute;