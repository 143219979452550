import React, { useState, useEffect } from "react";
import { login } from "../../services/authentication-service";
//import { initGenVariables } from "../../services/recapService";
import "./login.scss";
import logo from "../../assets/images/Togo-logo.png";
import visible from "../../assets/images/visibility_black.svg";
import visibleOff from "../../assets/images/visibility_off_black.svg";
import { useHistory } from "react-router-dom";
//import ProtectedRoute from "../../utils/ProtectedRoute";
//import Home from "../home/home";

function useForm(initialState = {}, validations = []) {
  const { isValid: initialIsValid, errors: initialErrors } = validate(
    validations,
    initialState
  );
  const [valuesItem, setValuesItem] = useState(initialState);
  const [errors, setErrors] = useState(initialErrors);
  const [isValid, setValid] = useState(initialIsValid);
  const [touched, setTouched] = useState({});
  const changeHandler = (event) => {
    const newValues = {
      ...valuesItem,
      [event.target.name]: event.target.value,
    };
    const { isValid, errors } = validate(validations, newValues);
    setValuesItem(newValues);
    setValid(isValid);
    setErrors(errors);
    setTouched({ ...touched, [event.target.name]: true });
  };
  return { valuesItem, changeHandler, isValid, errors, touched };
}

function isRequired(value) {
  return value != null && value.trim().length > 0;
}

function validate(validations, valuesItem) {
  const errors = validations
    .map((validation) => validation(valuesItem))
    .filter((validation) => typeof validation === "object");
  return {
    isValid: errors.length === 0,
    errors: errors.reduce((errors, error) => ({ ...errors, ...error }), {}),
  };
}

const Login = () => {
  const history = useHistory();
  //const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("logedIn")) {
      history.push("/about-project");
    }
  },
  // eslint-disable-next-line 
  []);

  /* Password hide and show */

  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /* Validations start here */

  const initialState = { username: "", password: "" };

  const validations = [
    ({ username }) =>
      isRequired(username) || { username: "L'identifiant es requis" },
    ({ password }) =>
      isRequired(password) || { password: "Le mot de passe est requis" },
  ];
  const { valuesItem, isValid, errors, changeHandler, touched } = useForm(
    initialState,
    validations
  );

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const submitHandler = (event, props, onSubmit = () => {}) => {
    setLoading(true);
    event.preventDefault();
    onSubmit(valuesItem);
    login(valuesItem.username, valuesItem.password).then(
      (res) => {
        console.log(localStorage.getItem("logedIn"));
        if (localStorage.getItem("logedIn")) {
          history.push("/tableau-de-bord");
          //window.location.reload();
        } else {
          const resMessage =
            (res && res.RESPONSE_MESSAGE && res.RESPONSE_MESSAGE) ||
            res.RESPONSE_MESSAGE ||
            res.RESPONSE_MESSAGE.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setErrorMessage(resMessage);
      }
    );
  };

  return (
    <div className="login-container">
      <div className="login-container-inner">
        <div className="login-container-inner-logo">
          <img src={logo} alt="logo" />
          <h1>Connexion</h1>
        </div>
        <form className="login-container-inner-form" onSubmit={submitHandler}>
          <div
            className={
              "login-container-inner-form-item " +
              (touched.username && errors.username ? "error" : "")
            }
          >
            <p>Identifiant</p>
            <input
              type="text"
              placeholder="Votre identifiant"
              name="username"
              onChange={changeHandler}
              value={valuesItem.username}
            />
            {touched.username && errors.username && (
              <div className="error">
                <p>{errors.username}</p>
              </div>
            )}
          </div>
          <div
            className={
              "login-container-inner-form-item password " +
              (touched.password && errors.password ? "error" : "")
            }
          >
            <p>Mot de passe</p>
            <input
              type={values.showPassword ? "text" : "password"}
              placeholder="Votre mot de passe"
              name="password"
              onChange={changeHandler}
              value={valuesItem.password}
            />
            <span
              className="hide-show"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? (
                <img src={visible} alt="eye" />
              ) : (
                <img src={visibleOff} alt="eye" />
              )}
            </span>
            {touched.password && errors.password && (
              <div className="error">
                <p>{errors.password}</p>
              </div>
            )}
          </div>
          <div className="login-container-inner-form-item button">
            <button type="submit" disabled={!isValid}>
              <span className="text">Se connecter</span>
              {loading && (
                <span className="spinner-box">
                  <span className="spinner-box-inner" />
                </span>
              )}
            </button>
          </div>
          {message && (
            <div className="global-message">
              <p style={{ color: "red" }}>{message}</p>
            </div>
          )}
          {errorMessage && (
            <div className="global-message error">
              <p>{errorMessage}</p>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;
