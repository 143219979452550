//import React from 'react';
import '../home/home.scss';
import Header from "../page_sections/header/Header";
import Footer from "../page_sections/footer/Footer";
import Timeline from "../timeline/Timeline";
import MapSection from "../map_section/MapSection";
//import { Redirect, useLocation } from 'react-router-dom';
//import { withRouter } from 'react-router-dom';

const Home = () => {
    
    return (
        <div>
            <Header />
            <div className="home-container">
                <Timeline/>
                <MapSection/>
            </div>
            <Footer />
        </div>
    )
}

export default Home;
