import React, { useState } from "react";
import "./MapSection.scss";
import Map from "./map/Map";
import AreaBlock from "./area-block/AreaBlock";
import { useHistory } from "react-router-dom";

const MapSection = () => {
  const history = useHistory();
  const [subdivisionLevel, setSubdivisionLevel] = useState("region");
  const [selectedRegion, setSelectedRegion] = useState(0);
  const [selectedPrefecture, setSelectedPrefecture] = useState(0);
  const [selectedCommune, setSelectedCommune] = useState(0);
  //const [selectedCanton, setSelectedCanton] = useState(0);

  const logedIn = () => {
    const isLogedIn = localStorage.getItem("logedIn");
    if (!isLogedIn) {
      history.push("/");
      //window.location.reload();
    }
  };

  logedIn();

  return (
    <div className="map-section">
      <Map
        subdivisionLevel={subdivisionLevel}
        setSubdivisionLevel={setSubdivisionLevel}
        selectedRegion={selectedRegion}
        setSelectedRegion={setSelectedRegion}
        selectedPrefecture={selectedPrefecture}
        setSelectedPrefecture={setSelectedPrefecture}
        selectedCommune={selectedCommune}
        setSelectedCommune={setSelectedCommune}
      />
      <AreaBlock
        subdivisionLevel={subdivisionLevel}
        setSubdivisionLevel={setSubdivisionLevel}
        selectedRegion={selectedRegion}
        setSelectedRegion={setSelectedRegion}
        selectedPrefecture={selectedPrefecture}
        setSelectedPrefecture={setSelectedPrefecture}
        selectedCommune={selectedCommune}
        setSelectedCommune={setSelectedCommune}
      />
    </div>
  );
};

export default MapSection;
