import React from "react";
import './sideMenu.scss';
import { NavLink } from "react-router-dom";
import homeIcon from "../../../assets/images/icons/home.svg";
import analyseIcon from "../../../assets/images/icons/analyse.svg";
import carteIcon from "../../../assets/images/icons/map.svg";
//import indicatorIcon from "../../../assets/images/icons/indicator.svg";
import dataIcon from "../../../assets/images/icons/data.svg";
import helpIcon from "../../../assets/images/icons/help.svg";
import aboutIcon from "../../../assets/images/icons/about.svg";
import resumeIcon from "../../../assets/images/icons/resume.svg";

const SideMenu = () => {
    return (
        <div className="side-menu-content">
            <ul>
                <li className="home">
                    <NavLink to={`/tableau-de-bord`}>
                        <img src={homeIcon} alt="icon"/>
                    </NavLink>
                </li>
                <li>
                    <NavLink className="disable-link" to={`/new-project/cartes`}>
                        <img src={carteIcon} alt="icon"/>
                        <span className="link-text">
                            Cartes
                        </span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className="disable-link" to={`/new-project/données`}>
                        <img src={dataIcon} alt="icon"/>
                        <span className="link-text">
                            Données
                        </span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/new-project/analyse`}>
                        <img src={analyseIcon} alt="icon"/>
                        <span className="link-text">
                            Indicateurs
                        </span>
                    </NavLink>
                </li>
                
                <li>
                    <NavLink className="disable-link" to={`/new-project/résumés`}>
                        <img src={resumeIcon} alt="icon"/>
                        <span className="link-text">
                            Résumés
                        </span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className="disable-link" to={`/new-project/a-propos`}>
                        <img src={aboutIcon} alt="icon"/>
                        <span className="link-text">
                            À propos
                        </span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className="disable-link" to={`/new-project/aide`}>
                        <img src={helpIcon} alt="icon"/>
                        <span className="link-text">
                            Aide
                        </span>
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}

export default SideMenu;